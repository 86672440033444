import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userApi } from '../apis';
import { User } from '../data/user/User';

export interface UserState {
  users: User[];
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  users: [],
  loading: false,
  error: null,
};

// Async action
export const getAllUser = createAsyncThunk(
  'user/getAllUser',
  async (_, { rejectWithValue }) => {
    try {
      const response = await userApi.get();
      return response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const fetchUserById = createAsyncThunk(
  'user/fetchUserById',
  async (id: string) => {
    const response = await userApi.getById(id);
    const data = response.data;
    return data as User;
  }
);

export const addUser = createAsyncThunk(
  'user/addUser',
  async (newUser: User) => {
    const response = await userApi.create(newUser);
    const data = await response.data;
    return data as User;
  }
);

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async (user: User) => {
    const response = await userApi.updateById(user._id, user);
    const data = await response.data;
    return data as User;
  }
);

export const deleteUser = createAsyncThunk(
  'user/deleteUser',
  async (id: string) => {
    const response = await userApi.deleteById(id);
    return response.data?.message || 'Delete successful!';
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(getAllUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch users';
      })
      .addCase(addUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.users.push(action.payload);
      })
      .addCase(updateUser.fulfilled, (state, action: PayloadAction<User>) => {
        const index = state.users.findIndex(
          (user) => user._id === action.payload._id
        );
        if (index !== -1) {
          state.users[index] = action.payload;
        }
      })
      .addCase(deleteUser.fulfilled, (state, action: PayloadAction<string>) => {
        state.users = state.users.filter((user) => user._id !== action.payload);
      });
  },
});
const userReducer = userSlice.reducer;
export { userReducer };
