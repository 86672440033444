import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { accountApi } from '../apis';
import { RootState } from '../store';

interface User {
  username: string;
  id: string;
  avatar: string;
  token: string;
}

interface AuthState {
  isAuthenticated: boolean;
  loading: boolean;
  user: User | null;
  error: string | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  loading: false,
  user: null,
  error: null,
};

// Define an async thunk for logging in
export const login = createAsyncThunk<
  User, // Return type of the payload creator
  { email: string; password: string }, // First argument to the payload creator
  { rejectValue: string } // Type for rejectWithValue
>('auth/login', async (credentials, { rejectWithValue }) => {
  try {
    const response = await accountApi.login(
      credentials.email,
      credentials.password
    );
    return response;
  } catch (error) {
    return rejectWithValue('Invalid credentials');
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<User>) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { logout } = authSlice.actions;
export const selectAuth = (state: RootState) => state.auth;
const authReducer = authSlice.reducer;
export { authReducer };
