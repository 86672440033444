import { memo } from 'react';
import CloudIcon from '../../../assets/images/cloud.svg';
import { cn } from '../../../utils/cn';

export const Cloud = memo(
  ({
    className,
    style,
    position,
  }: {
    className?: string;
    style?: React.CSSProperties;
    position?: 'top' | 'bottom';
  }) => {
    return (
      <img
        src={CloudIcon}
        alt='Cloud Img'
        className={cn(
          'w-[10%] block absolute z-30 ',
          className,
          position === 'top' ? ' top-0' : ' bottom-2',
          'hover:scale-110 transition-transform duration-300'
        )}
        style={style}
      />
    );
  }
);

Cloud.displayName = 'Cloud';
