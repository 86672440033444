import { Box, Container, Grid, IconButton, Typography } from '@mui/material';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';

const ResponsiveFooter = () => {
  return (
    <footer>
      <Box sx={{ flexGrow: 1, width: '100%' }}>
        {/* Footer */}
        <Box sx={{ bgcolor: 'grey.900', color: 'white', py: 8 }}>
          <Container>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant='h6' gutterBottom>
                  Company
                </Typography>
                <Box component='ul' sx={{ p: 0, listStyle: 'none', m: 0 }}>
                  <li>
                    <a
                      href='#'
                      style={{ color: 'white', textDecoration: 'none' }}
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      href='#'
                      style={{ color: 'white', textDecoration: 'none' }}
                    >
                      Careers
                    </a>
                  </li>
                  <li>
                    <a
                      href='#'
                      style={{ color: 'white', textDecoration: 'none' }}
                    >
                      Contact
                    </a>
                  </li>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant='h6' gutterBottom>
                  Resources
                </Typography>
                <Box component='ul' sx={{ p: 0, listStyle: 'none', m: 0 }}>
                  <li>
                    <a
                      href='#'
                      style={{ color: 'white', textDecoration: 'none' }}
                    >
                      Blog
                    </a>
                  </li>
                  <li>
                    <a
                      href='#'
                      style={{ color: 'white', textDecoration: 'none' }}
                    >
                      Documentation
                    </a>
                  </li>
                  <li>
                    <a
                      href='#'
                      style={{ color: 'white', textDecoration: 'none' }}
                    >
                      Support
                    </a>
                  </li>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant='h6' gutterBottom>
                  Legal
                </Typography>
                <Box component='ul' sx={{ p: 0, listStyle: 'none', m: 0 }}>
                  <li>
                    <a
                      href='#'
                      style={{ color: 'white', textDecoration: 'none' }}
                    >
                      Terms of Service
                    </a>
                  </li>
                  <li>
                    <a
                      href='#'
                      style={{ color: 'white', textDecoration: 'none' }}
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href='#'
                      style={{ color: 'white', textDecoration: 'none' }}
                    >
                      Cookie Policy
                    </a>
                  </li>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant='h6' gutterBottom>
                  Connect
                </Typography>
                <Box display='flex' gap={2}>
                  <IconButton href='#' sx={{ color: 'white' }}>
                    <FaFacebook />
                  </IconButton>
                  <IconButton href='#' sx={{ color: 'white' }}>
                    <FaTwitter />
                  </IconButton>
                  <IconButton href='#' sx={{ color: 'white' }}>
                    <FaInstagram />
                  </IconButton>
                  <IconButton href='#' sx={{ color: 'white' }}>
                    <FaLinkedin />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </footer>
  );
};

export default ResponsiveFooter;
