import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { factoryApi } from '../apis';
import { Factory } from '../data';

interface FactoryState {
  factories: Factory[];
  loading: boolean;
  error: string | null;
}

const initialState: FactoryState = {
  factories: [],
  loading: false,
  error: null,
};

export const getFactory = createAsyncThunk('factory/get', async () => {
  const response = await factoryApi.get();
  const data = response.data;
  return data as Factory[];
});

export const addFactory = createAsyncThunk(
  'factory/create',
  async (newFactory: Partial<Factory>) => {
    const response = await factoryApi.create(newFactory);
    const data = await response.data;
    return data as Factory;
  }
);

export const updateFactory = createAsyncThunk(
  'factory/update',
  async (factory: Partial<Factory>) => {
    const response = await factoryApi.updateById(factory._id!, factory);
    const data = await response.data;
    return data as Factory;
  }
);

export const deleteFactory = createAsyncThunk(
  'factory/delete',
  async (id: string) => {
    const response = await factoryApi.deleteById(id);
    return response.data?.message || 'Delete successful!';
  }
);

const factorySlice = createSlice({
  name: 'factory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFactory.pending, (state) => {
        (state.loading = true), (state.error = null);
      })
      .addCase(
        getFactory.fulfilled,
        (state, action: PayloadAction<Factory[]>) => {
          state.loading = false;
          state.factories = action.payload;
        }
      )
      .addCase(getFactory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to get factory!';
      })
      .addCase(
        addFactory.fulfilled,
        (state, action: PayloadAction<Factory>) => {
          state.factories.push(action.payload);
        }
      )
      .addCase(
        updateFactory.fulfilled,
        (state, action: PayloadAction<Factory>) => {
          const index = state.factories.findIndex(
            (factory) => factory._id === action.payload._id
          );
          if (index !== -1) state.factories[index] = action.payload;
        }
      )
      .addCase(
        deleteFactory.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.factories = state.factories.filter(
            (factory) => factory._id !== action.payload
          );
        }
      );
  },
});
const factoryReducer = factorySlice.reducer;
export { factoryReducer };
