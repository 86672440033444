import { FiUsers } from 'react-icons/fi';
import { IoMdBook } from 'react-icons/io';
import { PiStudentDuotone } from 'react-icons/pi';

export const programs = [
  {
    tag: 'fao-vaa',
    icon: '✈️',
    href: 'https://fao-vaa.edu.vn',
  },
  {
    tag: 'fba-vaa',
    icon: '✈️',
    href: '#',
  },
  {
    tag: 'fae-vaa',
    icon: '✈️',
    href: '#',
  },
  {
    tag: 'fee-vaa',
    icon: '✈️',
    href: 'https://feee-vaa.edu.vn',
  },
  {
    tag: 'fce-vaa',
    icon: '✈️',
    href: 'https://fcevaa.edu.vn/',
  },
  {
    tag: 'ate-vaa',
    icon: '✈️',
    href: 'https://www.ate.vaa.edu.vn/',
  },
  {
    tag: 'fit-vaa',
    icon: '✈️',
    href: 'https://fitvaa.edu.vn/',
  },
  {
    tag: 'ftas-vaa',
    icon: '✈️',
    href: 'https://ftas.vaa.edu.vn/',
  },
  {
    tag: 'ffl-vaa',
    icon: '✈️',
    href: '#',
  },
  {
    tag: 'bf-vaa',
    icon: '✈️',
    href: '#',
  },
];

export const stats = [
  { icon: IoMdBook, value: 100, label: 'Programs', suffix: '+' },
  { icon: FiUsers, value: 10000, label: 'Students', suffix: '+' },
  { icon: PiStudentDuotone, value: 88, label: 'GraduateRate', suffix: '%' },
];
