import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { studentApi } from '../apis';
import { Student } from '../data';

interface StudentState {
  students: Student[];
  loading: boolean;
  error: string | null;
}

const initialState: StudentState = {
  students: [],
  loading: false,
  error: null,
};

export const getStudent = createAsyncThunk('student/get', async () => {
  const response = await studentApi.get();
  const data = response.data;
  return data as Student[];
});

export const createStudent = createAsyncThunk(
  'student/create',
  async (newStudent: Partial<Student>) => {
    const response = await studentApi.create(newStudent);
    const data = await response.data;
    return data as Student;
  }
);

export const createManyStudent = createAsyncThunk(
  'student/createMany',
  async (newStudents: Partial<Student>[]) => {
    const response = await studentApi.createMany(newStudents);
    const data = await response.data;
    return data as Student[];
  }
);

export const updateStudent = createAsyncThunk(
  'student/update',
  async (student: Partial<Student>) => {
    const response = await studentApi.updateById(student._id!, student);
    const data = await response.data;
    return data as Student;
  }
);

export const deleteStudent = createAsyncThunk(
  'student/delete',
  async (id: string) => {
    const response = await studentApi.deleteById(id);
    return response.data?.message || 'Delete successful!';
  }
);

const studentSlice = createSlice({
  name: 'student',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStudent.pending, (state) => {
        (state.loading = true), (state.error = null);
      })
      .addCase(
        getStudent.fulfilled,
        (state, action: PayloadAction<Student[]>) => {
          state.loading = false;
          state.students = action.payload;
        }
      )
      .addCase(getStudent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to get student!';
      })
      .addCase(
        createStudent.fulfilled,
        (state, action: PayloadAction<Student>) => {
          state.students.push(action.payload);
        }
      )
      .addCase(
        createManyStudent.fulfilled,
        (state, action: PayloadAction<Student[]>) => {
          action.payload.forEach((student) => state.students.push(student));
        }
      )
      .addCase(
        updateStudent.fulfilled,
        (state, action: PayloadAction<Student>) => {
          const index = state.students.findIndex(
            (student) => student._id === action.payload._id
          );
          if (index !== -1) state.students[index] = action.payload;
        }
      )
      .addCase(
        deleteStudent.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.students = state.students.filter(
            (student) => student._id !== action.payload
          );
        }
      );
  },
});
const studentReducer = studentSlice.reducer;
export { studentReducer };
