import LandingPage from "./LandingPage";
import { useTranslation } from "react-i18next";
const Club = () => {
  const { t } = useTranslation();
  document.title =t("menuCLB");
  return (
    <>
      <LandingPage>
        <div>Club</div>
      </LandingPage>
    </>
  );
};

export default Club;
