import {
  Box,
  Card,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { History } from '../../../../data';
import { getHistory } from '../../../../feature';
import { RootState, useAppDispatch } from '../../../../store';
import { Scrollbar } from '../../../components/scrollbar';
import { DashboardContent } from '../../../layouts/dashboard';
import { UserTableHead } from '../../user/user-table-head';
import { UserTableToolbar } from '../../user/user-table-toolbar';
import { useTable } from '../../user/view';
import { HistoryTableRow } from '../history-table-row';
import { TableEmptyRows } from '../../table';
import { TableNoData } from '../../table';
import { applyFilter, emptyRows, getComparator } from '../utils';

const HistoryView = () => {
  const dispatch = useAppDispatch();
  const { history } = useSelector((state: RootState) => state.history);
  useEffect(() => {
    if (!history || history.length === 0) dispatch(getHistory());
  }, [dispatch, history]);

  const table = useTable();
  const [filterName, setFilterName] = useState('');
  
  const dataFiltered: History[] = applyFilter({
    inputData: history as unknown as History[],
    comparator: getComparator(table.order, table.orderBy),
    filterName,
  });

  const notFound = !dataFiltered.length && !!filterName;
  return (
    <DashboardContent maxWidth='xl'>
      <Box display='flex' alignItems='center' mb={5}>
        <Typography variant='h4' flexGrow={1}>
          History
        </Typography>
      </Box>

      <Card>
        <UserTableToolbar
          numSelected={table.selected.length}
          filterName={filterName}
          onFilterName={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFilterName(event.target.value);
            table.onResetPage();
          }}
        />

        <Scrollbar>
          <TableContainer sx={{ overflow: 'unset' }}>
            <Table sx={{ minWidth: 800 }}>
              <UserTableHead
                order={table.order}
                orderBy={table.orderBy}
                rowCount={history.length}
                numSelected={table.selected.length}
                onSort={table.onSort}
                onSelectAllRows={(checked) =>
                  table.onSelectAllRows(
                    checked,
                    history.map((item) => item.detail)
                  )
                }
                headLabel={[
                  { id: 'name', label: 'Name' },
                  { id: 'action', label: 'Action' },
                  { id: 'detail', label: 'Detail' },
                  // { id: 'active', label: 'Active', align: 'center' },
                  { id: 'createdAt', label: 'Create at' },
                  // { id: 'updatedAt', label: 'Update at' },
                  // { id: '_id', label: 'Action' },
                ]}
              />
              <TableBody>
                {dataFiltered
                  .slice(
                    table.page * table.rowsPerPage,
                    table.page * table.rowsPerPage + table.rowsPerPage
                  )
                  .map((row) => (
                    <HistoryTableRow
                      key={row.action}
                      row={row}
                      selected={table.selected.includes(row.action)}
                      onSelectRow={() => table.onSelectRow(row.action)}
                    />
                  ))}

                <TableEmptyRows
                  height={68}
                  emptyRows={emptyRows(
                    table.page,
                    table.rowsPerPage,
                    history.length
                  )}
                />

                {notFound && <TableNoData searchQuery={filterName} />}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          component='div'
          page={table.page}
          count={history.length}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={table.onChangeRowsPerPage}
        />
      </Card>
    </DashboardContent>
  );
};

export { HistoryView };
