import * as Dropdown from '@radix-ui/react-dropdown-menu';
import { DropdownButton } from '../../../ui/dropdown';
import { Icon } from '../../../ui/icon';
import { Surface } from '../../../ui/surface';
import { Toolbar } from '../../../ui/toolbar';

export type AIDropdownProps = {
  onSimplify?: () => void;
  onFixSpelling?: () => void;
  onMakeShorter?: () => void;
  onMakeLonger?: () => void;
  onEmojify?: () => void;
  onTldr?: () => void;
  onCompleteSentence?: () => void;
};

export const AIDropdown = ({
  onCompleteSentence,
  onEmojify,
  onFixSpelling,
  onMakeLonger,
  onMakeShorter,
  onSimplify,
  onTldr,
}: AIDropdownProps) => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>
        <Toolbar.Button
          className='text-purple-500 hover:text-purple-600 active:text-purple-600 '
          activeClassname='text-purple-600 hover:text-purple-600 '
        >
          <Icon name='Sparkles' className='mr-1' />
          AI Tools
          <Icon name='ChevronDown' className='w-2 h-2 ml-1' />
        </Toolbar.Button>
      </Dropdown.Trigger>
      <Dropdown.Content asChild>
        <Surface className='p-2 min-w-[10rem]'>
          <Dropdown.Item onClick={onSimplify}>
            <DropdownButton>
              <Icon name='CircleSlash' />
              Simplify
            </DropdownButton>
          </Dropdown.Item>
          <Dropdown.Item onClick={onFixSpelling}>
            <DropdownButton>
              <Icon name='Eraser' />
              Fix spelling & grammar
            </DropdownButton>
          </Dropdown.Item>
          <Dropdown.Item onClick={onMakeShorter}>
            <DropdownButton>
              <Icon name='ArrowLeftToLine' />
              Make shorter
            </DropdownButton>
          </Dropdown.Item>
          <Dropdown.Item onClick={onMakeLonger}>
            <DropdownButton>
              <Icon name='ArrowRightToLine' />
              Make longer
            </DropdownButton>
          </Dropdown.Item>
          <Dropdown.Sub>
            <Dropdown.SubTrigger>
              <DropdownButton>
                <Icon name='Mic' />
                Change tone
                <Icon name='ChevronRight' className='w-4 h-4 ml-auto' />
              </DropdownButton>
            </Dropdown.SubTrigger>
          </Dropdown.Sub>
          <Dropdown.Item onClick={onTldr}>
            <DropdownButton>
              <Icon name='Ellipsis' />
              Tl;dr:
            </DropdownButton>
          </Dropdown.Item>
          <Dropdown.Item onClick={onEmojify}>
            <DropdownButton>
              <Icon name='SmilePlus' />
              Emojify
            </DropdownButton>
          </Dropdown.Item>
          <Dropdown.Sub>
            <Dropdown.SubTrigger>
              <DropdownButton>
                <Icon name='Languages' />
                Translate
                <Icon name='ChevronRight' className='w-4 h-4 ml-auto' />
              </DropdownButton>
            </Dropdown.SubTrigger>
          </Dropdown.Sub>
          <Dropdown.Item onClick={onCompleteSentence}>
            <DropdownButton>
              <Icon name='PenLine' />
              Complete sentence
            </DropdownButton>
          </Dropdown.Item>
        </Surface>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
