import { axiosConfigs } from '.';
import { Club } from '../data';

const BASE_API = '/club';
class ClubApi {
  get = async () => {
    return await axiosConfigs.get(BASE_API);
  };

  getById = async (id: string) => {
    return await axiosConfigs.get(`${BASE_API}/${id}`);
  };
  create = async (newClub: Partial<Club>) => {
    return await axiosConfigs.post(`${BASE_API}`, newClub);
  };
  updateById = async (id: string, club: Partial<Club>) => {
    return await axiosConfigs.put(`${BASE_API}/${id}`, club);
  };
  deleteById = async (id: string) => {
    return await axiosConfigs.delete(`${BASE_API}/${id}`);
  };
}

const clubApi = new ClubApi();
export { clubApi };
