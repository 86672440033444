import { motion, useScroll, useTransform } from 'framer-motion';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { WordFadeIn } from '../../ui/word-fade-in';
import { WordPullUp } from '../../ui/word-pull-up';
import useWindowSize from '../../useWindowSize/useWindowSize';
import { Cloud } from './cloud';
import { IconBanner } from './icon-banner';

const logo_vaa = require('../../../assets/images/logo_vaa.png');
const logo_hsv = require('../../../assets/images/huy_hieu_hoi_sinh_vien_viet_nam.png');
const logo_doan = require('../../../assets/images/huy_hieu_doan.png');

export const Banner = () => {
  const { height } = useWindowSize();
  const { t } = useTranslation();

  const bannerHeight = useMemo(() => {
    return height - (document.getElementById('header-app')?.offsetHeight || 0);
  }, [height]);

  const targetRef = useRef(null);
  const cloudRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ['start end', 'end start'],
  });
  const { scrollYProgress: cloudUpScrollYProgress } = useScroll({
    target: cloudRef,
    offset: ['start end', 'end start'],
  });

  const y = useTransform(scrollYProgress, [0, 1], [270, -250]);
  const opacity = useTransform(scrollYProgress, [0.5, 0.8], [1, 0]);

  const yCloudUp = useTransform(cloudUpScrollYProgress, [0.8, 1], [-100, 100]);
  const opacityCloudUp = useTransform(
    cloudUpScrollYProgress,
    [0.8, 1],
    [1, 0.6]
  );

  return (
    <section
      className='relative w-full flex flex-col bg-sky-100/70'
      style={{
        height: bannerHeight,
      }}
    >
      <motion.div
        className='absolute relative top-0 left-0 z-30'
        style={{
          y: yCloudUp,
          opacity: opacityCloudUp,
        }}
        ref={cloudRef}
      >
        <Cloud className='opacity-80 left-0 ' position='top' />
        <Cloud className='left-[5%] w-[10%] mt-10' position='top' />
        <Cloud className='left-[8%] w-[15%] -mt-6 opacity-70' position='top' />
        <Cloud className='left-0 w-[17%] mt-16 -ml-20 ' position='top' />
        <Cloud className='ml-[50%] w-[20%]' position='top' />
        <Cloud className='ml-[62%] w-[16%] opacity-70' position='top' />
        <Cloud className='ml-[80%] w-[12%] mt-12 opacity-80' position='top' />
        <img
          src={require('../../../assets/images/flight.webp')}
          alt=''
          className='absolute top-20 left-0 z-30 -rotate-[15deg] w-[20%] '
        />
      </motion.div>

      <div className=' flex flex-row gap-x-4 w-full justify-center mt-36 relative z-40'>
        <IconBanner
          url={logo_doan}
          alt='Đoàn TNCS Hồ Chí Minh'
          width={112}
          height={112}
        />
        <IconBanner
          url={logo_vaa}
          alt='Học Viện Hàng Không'
          width={112}
          height={112}
          className='-mt-2'
        />
        <IconBanner
          url={logo_hsv}
          alt='Hội sinh viên'
          width={112}
          height={112}
        />
      </div>
      <motion.div
        className='relative flex flex-col items-center w-full mt-12 z-50'
        style={{
          y,
          opacity,
        }}
        ref={targetRef}
      >
        <WordFadeIn
          words={t('dtn-hsv')}
          className='text-3xl text-primary md:text-6xl md:leading-[5rem] lg:text-7xl'
        />
        ;
        <WordPullUp
          words={t('vaa')}
          className='text-2xl font-bold tracking-[-0.02em] text-primary md:text-6xl md:leading-[5rem] lg:text-7xl'
        />
      </motion.div>
      <Cloud className='opacity-80 left-0 -mt-20' position='bottom' />
      <Cloud className='left-[5%] w-[15%] -mt-10' position='bottom' />
      <Cloud className='left-[60%] opacity-80 -mt-30' position='bottom' />
      <Cloud className='left-[70%] w-[15%] -mt-10' position='bottom' />
    </section>
  );
};

Banner.displayName = 'Banner';
