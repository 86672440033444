import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  authReducer,
  academicYearReducer,
  userReducer,
  historyReducer,
  categoryReducer,
  classReducer,
  clubReducer,
  factoryReducer,
  studentReducer,
  bannerReducer,
} from '../feature';
const store = configureStore({
  reducer: {
    academicYear: academicYearReducer,
    auth: authReducer,
    banner: bannerReducer,
    category: categoryReducer,
    class: classReducer,
    club: clubReducer,
    factory: factoryReducer,
    history: historyReducer,
    student: studentReducer,
    user: userReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> =
  useSelector.withTypes<RootState>();

export default store;
