import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import styles from "./SignIn.module.scss";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { validateLogin } from '../../services/validateLogin';
import { useEffect } from 'react';
import { Stack } from '@mui/material';
import { accountApi } from '../../apis';
import { History, TokenDecode } from '../../data';
import { jwtDecode } from 'jwt-decode';
import { useAppDispatch } from '../../store';
import { addHistory } from '../../feature';
import { unwrapResult } from '@reduxjs/toolkit';
import { Action } from '../../constant';
function Copyright(props: any) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}
    >
      {'Copyright © '}
      <Link color='inherit' href='/'>
        {/* Vietnam Aviation Academy  */}
        VAA Union of Students
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
interface IFormInput {
  email: string;
  password: string;
}
export default function SignIn() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  // yup
  const schema = yup.object().shape({
    email: yup.string().required(t('requireEmail')).email(t('validEmail')),
    password: yup
      .string()
      .required(t('requiredPassword'))
      .min(8, t('minPassword')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    validateLogin();
  }, []);

  const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
    accountApi.login(data.email, data.password).then(
      (res: any) => {
        localStorage.setItem('token', JSON.stringify(res['token']));
        const decodeData = jwtDecode(res['token']) as TokenDecode;
        const user = decodeData.id;
        const dataHistory: Partial<History> = {
          user,
          action: Action.LOGIN,
          detail: `Login by ${decodeData.name}`,
        };
        dispatch(addHistory(dataHistory));
        window.location.replace('/admin');
      },
      (error: any) => {
        console.log(error);
      }
    );
  };
  return (
    <Container className={styles.container} component='main' maxWidth='xs'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Stack direction={'row'} spacing={2}>
          <Avatar sx={{ m: 1 }}>
            <img src='/assets/images/HSV.svg' srcSet='' alt='' />
          </Avatar>
          <Avatar sx={{ m: 1 }}>
            <img src='/assets/images/DTN.png' srcSet='' alt='' />
          </Avatar>
          <Avatar sx={{ m: 1 }}>
            <img src='/assets/images/VAA.svg' srcSet='' alt='' />
          </Avatar>
        </Stack>
        <Stack mt={2} justifyContent='center' alignItems='center'>
          <Typography component='h1' variant='h5' sx={{ whiteSpace: 'nowrap' }}>
            Hội sinh viên
          </Typography>
          <Typography component='h1' variant='h5' sx={{ whiteSpace: 'nowrap' }}>
            Học viện Hàng không Việt Nam
          </Typography>
        </Stack>
        <Box component='form' onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
          <TextField
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email Address'
            autoComplete='email'
            autoFocus
            {...register('email')}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
            {...register('password')}
            error={!!errors.password}
            helperText={errors.password?.message}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2, padding: 1 }}
          >
            Sign In
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
