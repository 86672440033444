import { motion } from 'framer-motion';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { stats } from '../../constant';
import NumberTicker from './number-ticker';

export const StatsComponent = memo(() => {
  const { t } = useTranslation();

  return (
    <section className='py-16 bg-white z-20 relative'>
      <div className='container mx-auto px-4'>
        <div className='flex flex-wrap justify-center gap-8'>
          {stats.map((stat, index) => (
            <motion.div
              key={`stat-${index}`}
              className='text-center'
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: index * 0.1 }}
            >
              <stat.icon className='w-12 h-12 mx-auto mb-4 text-blue-600' />
              <h2 className='text-4xl font-bold text-gray-800 mb-2'>
                <NumberTicker value={stat.value} />
                {stat.suffix}
              </h2>
              <p className='text-gray-600 text-xl font-normal'>
                {t(stat.label)}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
});

StatsComponent.displayName = 'StatsComponent';
