import { lazy, Suspense } from 'react';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';

import Box from '@mui/material/Box';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

import RequireAuth from '../../components/authenticates/RequireAuth';
import { BlockEditor } from '../components/editor/block-editor';
import { DashboardLayout } from '../layouts/dashboard';
import {
  AcademicCreate,
  AcademicUpdate,
  AcademicView,
} from '../sections/academic-year/view';
import {
  BannerCreate,
  BannerUpdate,
  BannerView,
} from '../sections/banner/view';
import { BlogUpdate } from '../sections/blog/view/blog-update';
import {
  CategoryCreate,
  CategoryUpdate,
  CategoryView,
} from '../sections/category/view';
import { ClassCreate, ClassUpdate, ClassView } from '../sections/class/view';
import { ClubCreate, ClubUpdate, ClubView } from '../sections/club/view';
import { FactoryCreate, FactoryUpdate, FactoryView } from '../sections/factory/view';
import { HistoryView } from '../sections/history/view';
import {
  StudentCreate,
  StudentUpdate,
  StudentView,
} from '../sections/student/view';
import { UserCreate } from '../sections/user/view';
import { UserUpdate } from '../sections/user/view/user-update';
import { varAlpha } from '../theme/styles';

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import('../pages/home'));
export const BlogPage = lazy(() => import('../pages/news'));
export const UserPage = lazy(() => import('../pages/user'));
export const ProductsPage = lazy(() => import('../pages/products'));
export const Page404 = lazy(() => import('../pages/page-not-found'));

// ----------------------------------------------------------------------

const renderFallback = (
  <Box
    display='flex'
    alignItems='center'
    justifyContent='center'
    flex='1 1 auto'
  >
    <LinearProgress
      sx={{
        width: 1,
        maxWidth: 320,
        bgcolor: (theme) => varAlpha(theme.palette.text.primaryChannel, 0.16),
        [`& .${linearProgressClasses.bar}`]: { bgcolor: 'text.primary' },
      }}
    />
  </Box>
);

export function Router() {
  return useRoutes([
    {
      path: '',
      element: (
        <DashboardLayout>
          <Suspense fallback={renderFallback}>
            <RequireAuth redirectTo={'/login'}>
              <Outlet />
            </RequireAuth>
          </Suspense>
        </DashboardLayout>
      ),
      children: [
        { path: '', element: <HomePage />, index: true },
        { path: 'academic-year', element: <AcademicView /> },
        { path: 'academic-year/create', element: <AcademicCreate /> },
        { path: 'academic-year/update/:id', element: <AcademicUpdate /> },

        { path: 'blog', element: <BlogPage /> },
        { path: 'blog/update/:id', element: <BlogUpdate /> },

        { path: 'banner', element: <BannerView /> },
        { path: 'banner/create', element: <BannerCreate /> },
        { path: 'banner/update/:id', element: <BannerUpdate /> },

        { path: 'category', element: <CategoryView /> },
        { path: 'category/create', element: <CategoryCreate /> },
        { path: 'category/update/:id', element: <CategoryUpdate /> },

        { path: 'class', element: <ClassView /> },
        { path: 'class/create', element: <ClassCreate /> },
        { path: 'class/update/:id', element: <ClassUpdate /> },

        { path: 'club', element: <ClubView /> },
        { path: 'club/create', element: <ClubCreate /> },
        { path: 'club/update/:id', element: <ClubUpdate /> },

        { path: 'factory', element: <FactoryView /> },
        { path: 'factory/create', element: <FactoryCreate /> },
        { path: 'factory/update/:id', element: <FactoryUpdate /> },

        { path: 'history', element: <HistoryView /> },

        { path: 'user', element: <UserPage /> },
        { path: 'user/create-user', element: <UserCreate /> },
        { path: 'user/update-user/:id', element: <UserUpdate /> },

        { path: 'products', element: <ProductsPage /> },

        { path: 'student', element: <StudentView /> },
        { path: 'student/create', element: <StudentCreate /> },
        { path: 'student/update/:id', element: <StudentUpdate /> },
      ],
    },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to='/404' replace />,
    },
    {
      path: 'blog/create',
      element: (
        <RequireAuth redirectTo={'/login'}>
          <BlockEditor />
        </RequireAuth>
      ),
    },
  ]);
}
