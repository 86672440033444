import * as Popover from '@radix-ui/react-popover';
import { BubbleMenu, Editor } from '@tiptap/react';
import { memo } from 'react';
import { useTextmenuCommands } from './hooks/useTextMenuCommands';
import { useTextmenuStates } from './hooks/useTextMenuState';

import { Icon } from '../../ui/icon';
import { Toolbar } from '../../ui/toolbar';
import { AIDropdown } from './components/ai-dropdown';
import { ContentTypePicker } from './components/content-type-picker';
import { EditLinkPopover } from './components/EditLinkPopover';
import { FontFamilyPicker } from './components/font-family-picker';
import { FontSizePicker } from './components/font-size-picker';
import { useTextmenuContentTypes } from './hooks/useTextmenuContentTypes';

// We memorize the button so each button is not rerendered
// on every editor state change
const MemoButton = memo(Toolbar.Button);
const MemoFontSizePicker = memo(FontSizePicker);
const MemoContentTypePicker = memo(ContentTypePicker);
const MemoFontFamilyPicker = memo(FontFamilyPicker);

export type TextMenuProps = {
  editor: Editor;
};

export const TextMenu = ({ editor }: TextMenuProps) => {
  const commands = useTextmenuCommands(editor);
  const states = useTextmenuStates(editor);
  const blockOptions = useTextmenuContentTypes(editor);

  return (
    <BubbleMenu
      tippyOptions={{
        popperOptions: {
          placement: 'top-start',
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                boundary: 'viewport',
                padding: 8,
              },
            },
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['bottom-start', 'top-end', 'bottom-end'],
              },
            },
          ],
        },
        maxWidth: 'calc(100vw - 16px)',
      }}
      editor={editor}
      pluginKey='textMenu'
      shouldShow={states.shouldShow}
      updateDelay={100}
    >
      <Toolbar.Wrapper>
        <AIDropdown
        //   onCompleteSentence={commands.onCompleteSentence}
        //   onEmojify={commands.onEmojify}
        //   onFixSpelling={commands.onFixSpelling}
        //   onMakeLonger={commands.onMakeLonger}
        //   onMakeShorter={commands.onMakeShorter}
        //   onSimplify={commands.onSimplify}
        //   onTldr={commands.onTldr}
        //   onTranslate={commands.onTranslate}
        />
        <Toolbar.Divider />
        <MemoContentTypePicker options={blockOptions} />
        <MemoFontFamilyPicker value={states.currentFont || ''} />
        <MemoFontSizePicker
          onChange={commands.onSetFontSize}
          value={states.currentSize || ''}
        />
        <Toolbar.Divider />
        <MemoButton
          tooltip='Bold'
          tooltipShortcut={['Mod', 'B']}
          onClick={commands.onBold}
          active={states.isBold}
        >
          <Icon name='Bold' />
        </MemoButton>
        <MemoButton
          tooltip='Italic'
          tooltipShortcut={['Mod', 'I']}
          onClick={commands.onItalic}
          active={states.isItalic}
        >
          <Icon name='Italic' />
        </MemoButton>
        <MemoButton
          tooltip='Underline'
          tooltipShortcut={['Mod', 'U']}
          onClick={commands.onUnderline}
          active={states.isUnderline}
        >
          <Icon name='Underline' />
        </MemoButton>
        <MemoButton
          tooltip='Strikehrough'
          tooltipShortcut={['Mod', 'Shift', 'S']}
          onClick={commands.onStrike}
          active={states.isStrike}
        >
          <Icon name='Strikethrough' />
        </MemoButton>
        <MemoButton
          tooltip='Code'
          tooltipShortcut={['Mod', 'E']}
          onClick={commands.onCode}
          active={states.isCode}
        >
          <Icon name='Code' />
        </MemoButton>
        <MemoButton tooltip='Code block' onClick={commands.onCodeBlock}>
          <Icon name='FileCode' />
        </MemoButton>
        <EditLinkPopover onSetLink={commands.onLink} />
        <Popover.Root>
          <Popover.Trigger asChild>
            <MemoButton
              active={!!states.currentHighlight}
              tooltip='Highlight text'
            >
              <Icon name='Highlighter' />
            </MemoButton>
          </Popover.Trigger>
        </Popover.Root>
        <Popover.Root>
          <Popover.Trigger asChild>
            <MemoButton active={!!states.currentColor} tooltip='Text color'>
              <Icon name='Palette' />
            </MemoButton>
          </Popover.Trigger>
        </Popover.Root>
        <Popover.Root>
          <Popover.Trigger asChild>
            <MemoButton tooltip='More options'>
              <Icon name='EllipsisVertical' />
            </MemoButton>
          </Popover.Trigger>
          <Popover.Content side='top' asChild>
            <Toolbar.Wrapper>
              <Toolbar.Divider />
              <MemoButton
                tooltip='Align left'
                tooltipShortcut={['Shift', 'Mod', 'L']}
                onClick={commands.onAlignLeft}
                active={states.isAlignLeft}
              >
                <Icon name='AlignLeft' />
              </MemoButton>
              <MemoButton
                tooltip='Align center'
                tooltipShortcut={['Shift', 'Mod', 'E']}
                onClick={commands.onAlignCenter}
                active={states.isAlignCenter}
              >
                <Icon name='AlignCenter' />
              </MemoButton>
              <MemoButton
                tooltip='Align right'
                tooltipShortcut={['Shift', 'Mod', 'R']}
                onClick={commands.onAlignRight}
                active={states.isAlignRight}
              >
                <Icon name='AlignRight' />
              </MemoButton>
              <MemoButton
                tooltip='Justify'
                tooltipShortcut={['Shift', 'Mod', 'J']}
                onClick={commands.onAlignJustify}
                active={states.isAlignJustify}
              >
                <Icon name='AlignJustify' />
              </MemoButton>
            </Toolbar.Wrapper>
          </Popover.Content>
        </Popover.Root>
      </Toolbar.Wrapper>
    </BubbleMenu>
  );
};
