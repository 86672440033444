import { axiosConfigs } from '.';
import { AcademicYear } from '../data';

const BASE_API = '/academic-year';
class AcademicYearApi {
  get = async () => {
    return await axiosConfigs.get(BASE_API);
  };

  getById = async (id: string) => {
    return await axiosConfigs.get(`${BASE_API}/${id}`);
  };
  create = async (newAcademicYear: Partial<AcademicYear>) => {
    return await axiosConfigs.post(`${BASE_API}`, newAcademicYear);
  };
  updateById = async (id: string, academicYear: Partial<AcademicYear>) => {
    return await axiosConfigs.put(`${BASE_API}/${id}`, academicYear);
  };
  deleteById = async (id: string) => {
    return await axiosConfigs.delete(`${BASE_API}/${id}`);
  };
}

const academicYearApi = new AcademicYearApi();
export { academicYearApi };
