import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { academicYearApi } from '../apis';
import { AcademicYear } from '../data';

interface AcademicYearState {
  academicYear: AcademicYear[];
  loading: boolean;
  error: string | null;
}

const initialState: AcademicYearState = {
  academicYear: [],
  loading: false,
  error: null,
};

export const getAcademicYear = createAsyncThunk(
  'academicYear/get',
  async () => {
    const response = await academicYearApi.get();
    const data = response.data;
    return data as AcademicYear[];
  }
);

export const getAcademicYearById = createAsyncThunk(
  'academicYear/getById',
  async (id: string) => {
    const response = await academicYearApi.getById(id);
    const data = response.data;
    return data as AcademicYear;
  }
);

export const addAcademicYear = createAsyncThunk(
  'academicYear/create',
  async (newAcademicYear: Partial<AcademicYear>) => {
    const response = await academicYearApi.create(newAcademicYear);
    const data = await response.data;
    return data as AcademicYear;
  }
);

export const updateAcademicYear = createAsyncThunk(
  'academicYear/update',
  async (academicYear: Partial<AcademicYear>) => {
    const response = await academicYearApi.updateById(
      academicYear._id!,
      academicYear
    );
    const data = await response.data;
    return data as AcademicYear;
  }
);

export const deleteAcademicYear = createAsyncThunk(
  'academicYear/delete',
  async (id: string) => {
    const response = await academicYearApi.deleteById(id);
    return response.data?.message || 'Delete successful!';
  }
);

const academicYearSlice = createSlice({
  name: 'academicYear',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAcademicYear.pending, (state) => {
        (state.loading = true), (state.error = null);
      })
      .addCase(
        getAcademicYear.fulfilled,
        (state, action: PayloadAction<AcademicYear[]>) => {
          state.loading = false;
          state.academicYear = action.payload;
        }
      )
      .addCase(getAcademicYear.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to get academicYear!';
      })
      .addCase(
        addAcademicYear.fulfilled,
        (state, action: PayloadAction<AcademicYear>) => {
          state.academicYear.push(action.payload);
        }
      )
      .addCase(
        updateAcademicYear.fulfilled,
        (state, action: PayloadAction<AcademicYear>) => {
          const index = state.academicYear.findIndex(
            (academic) => academic._id === action.payload._id
          );
          if (index !== -1) state.academicYear[index] = action.payload;
        }
      )
      .addCase(
        deleteAcademicYear.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.academicYear = state.academicYear.filter(
            (academic) => academic._id !== action.payload
          );
        }
      );
  },
});
const academicYearReducer = academicYearSlice.reducer;
export { academicYearReducer };
