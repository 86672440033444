import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { images } from '../../assets/images';
import { LANGUAGE } from '../../services/constants';
import useWindowSize from '../useWindowSize/useWindowSize';

function ResponsiveAppBar() {
  const { t, i18n } = useTranslation();
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const pages = [
    { menu: t('menuHome'), route: '/' },
    { menu: t('menuNews'), route: '/news' },
    { menu: t('menuStudentOf5Merits'), route: '/studento5m' },
    { menu: t('menuCLB'), route: '/club' },
    { menu: t('menuSupportStudent'), route: '/support' },
    { menu: t('menuGoodStories'), route: '/good-stories' },
  ];

  const [anchorElMenuLanguage, setAnchorElMenuLanguage] =
    React.useState<null | HTMLElement>(null);
  const [language, setLanguage] = React.useState('vi');
  const openMenuLanguage = Boolean(anchorElMenuLanguage);

  React.useEffect(() => {
    loadLanguage();
  }, []);

  const loadLanguage = () => {
    let lang = localStorage.getItem(LANGUAGE) as any;
    if (lang) setLanguage(lang);
  };
  const handleCloseMenuLanguage = () => {
    setAnchorElMenuLanguage(null);
  };

  const handleClickMenuLanguage = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElMenuLanguage(event.currentTarget);
  };

  const handleSetLanguage = (value: any) => {
    setAnchorElMenuLanguage(null);
    setLanguage(value);
    localStorage.setItem(LANGUAGE, value);
    i18n.changeLanguage(value);
  };
  return (
    <AppBar position='static' id='header-app' className='z-[9999]'>
      <Container maxWidth='lg'>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 0 }}>
            <Avatar
              onClick={() => navigate('/')}
              alt={t('hsv')}
              src={images.HSV}
              sx={{ display: { xs: 'none', md: 'block' } }}
            />
          </Box>

          <Typography
            variant='h6'
            noWrap
            component='a'
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              paddingLeft: 2,
              zIndex: 1,
            }}
          >
            HSV
          </Typography>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
              fontFamily: 'sans-serif',
            }}
          >
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page, index) => (
                <MenuItem key={index} onClick={() => navigate(page.route)}>
                  <Typography textAlign='center'>{page.menu}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'center',
            }}
          >
            {pages.map((page, index) => (
              <Button
                key={index}
                onClick={() => navigate(page.route)}
                sx={{ my: 2, color: 'white', display: 'block', mx: 1 }}
              >
                {page.menu}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 1, zIndex: 1 }}>
            <Avatar
              onClick={() => navigate('/')}
              alt={t('hsv')}
              src={images.HSV}
              sx={{
                display: { xs: 'block', md: 'none' },
                justifyContent: 'center',
              }}
            />
          </Box>

          <div className=''>
            <Button
              id='basic-button-language'
              aria-controls={openMenuLanguage ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={openMenuLanguage ? 'true' : undefined}
              onClick={handleClickMenuLanguage}
            >
              {language === 'en' && (
                <img
                  src={images.English}
                  srcSet={images.English}
                  alt=''
                  width={24}
                  loading='lazy'
                />
              )}
              {language === 'vi' && (
                <img
                  src={images.Vietnam}
                  srcSet={images.Vietnam}
                  alt=''
                  width={24}
                  loading='lazy'
                />
              )}
            </Button>
            <Menu
              id='basic-menu-language'
              anchorEl={anchorElMenuLanguage}
              open={openMenuLanguage}
              onClose={handleCloseMenuLanguage}
              MenuListProps={{
                'aria-labelledby': 'basic-button-language',
              }}
            >
              {language === 'en' && (
                <MenuItem onClick={() => handleSetLanguage('vi')}>
                  <img src={images.Vietnam} srcSet={images.Vietnam} alt='' />
                  <span style={{ marginLeft: '10px' }}>Tiếng Việt</span>
                </MenuItem>
              )}
              {language === 'vi' && (
                <MenuItem onClick={() => handleSetLanguage('en')}>
                  <img src={images.English} srcSet={images.English} alt='' />
                  <span style={{ marginLeft: '10px' }}>English</span>
                </MenuItem>
              )}
            </Menu>
          </div>
        </Toolbar>
      </Container>
      {/* {width > 750 ? (
        <div style={{ backgroundColor: 'black', width: '100%' }}>
          <Container>
            <div className='my-2 d-flex align-center justify-content-between'>
              <Stack alignItems={'center'} direction={'row'} spacing={2}>
                <Link
                  key={1}
                  to={'/introduce'}
                  component={RouterLink}
                  underline='none'
                  color={'white'}
                >
                  {t('introduce')}
                </Link>
                <Link
                  key={2}
                  to={'/HCMCYoU'}
                  component={RouterLink}
                  underline='none'
                  color={'white'}
                >
                  {t('dtn')}
                </Link>
                <Link
                  key={3}
                  to={'/email'}
                  component={RouterLink}
                  color={'white'}
                  underline='none'
                >
                  {t('email')}
                </Link>
                <Link
                  key={4}
                  to={'/online-office'}
                  component={RouterLink}
                  color={'white'}
                  underline='none'
                >
                  {t('onlineOffice')}
                </Link>
              </Stack>
            </div>
          </Container>
        </div>
      ) : null} */}
    </AppBar>
  );
}
export default ResponsiveAppBar;
