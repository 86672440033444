import { LocalStorageKey } from '../constant';

export const setLastHref = (href: string) => {
  if (href.includes('/login')) {
    return;
  }

  localStorage.setItem(LocalStorageKey.LAST_HREF, href);
};

export const removeLastHref = () => {
  localStorage.removeItem(LocalStorageKey.LAST_HREF);
};

export const getLastHref = () => {
  try {
    return localStorage.getItem(LocalStorageKey.LAST_HREF);
  } catch {
    return;
  }
};
