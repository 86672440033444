import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../../../store';
import { Iconify } from '../../../components/iconify';
import { DashboardContent } from '../../../layouts/dashboard';

interface FormData {
  name: string;
  email: string;
  factory: string;
  class: string;
  club: string;
  image: string | null;
}

// Define error types
interface Errors {
  name?: string;
  email?: string;
  password?: string;
  factory?: string;
  class?: string;
  club?: string;
}

const FormContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  maxWidth: 600,
  margin: 'auto',
  marginTop: theme.spacing(4),
  backgroundImage: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const ImagePreview = styled('img')({
  width: '100%',
  maxHeight: 400,
  objectFit: 'cover',
  marginTop: 8,
  borderRadius: 4,
});

const UserUpdate: React.FC = () => {
  const { id } = useParams();
  const { users } = useSelector((state: RootState) => state.user);
  const userDetail = users.filter((item) => item._id === id)[0];
  console.log(userDetail);
  const [formData, setFormData] = useState<FormData>({
    name: userDetail?.name,
    email: userDetail?.email,
    factory: userDetail?._id,
    class: userDetail?._id,
    club: userDetail?._id,
    image: userDetail?.image,
  });

  const [errors, setErrors] = useState<Errors>({});
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (
    e: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }
    >
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name as keyof FormData]: value as string });
    validateField(name as string, value as string);
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name as keyof FormData]: value });
    validateField(name as string, value as string);
  };

  const validateField = (name: string, value: string) => {
    let newErrors: Errors = { ...errors };
    switch (name) {
      case 'name':
        newErrors.name = value.trim() === '' ? 'Name is required' : '';
        break;
      case 'email':
        newErrors.email = !/^\S+@\S+\.\S+$/.test(value)
          ? 'Invalid email format'
          : '';
        break;
      case 'password':
        newErrors.password =
          value.length < 8 ? 'Password must be at least 8 characters long' : '';
        break;
      case 'factory':
      case 'class':
      case 'club':
        newErrors[name as keyof Errors] =
          value === ''
            ? `${name.charAt(0).toUpperCase() + name.slice(1)} selection is required`
            : '';
        break;
      default:
        break;
    }
    setErrors(newErrors);
  };

  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFormData({ ...formData, image: URL.createObjectURL(file) });
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    // Simulate API call
    setTimeout(() => {
      setLoading(false);
      console.log('Form submitted:', formData);
      // Reset form or navigate to next page
    }, 2000);
  };
  const navigate = useNavigate();
  const back = () => {
    navigate('/admin/user');
  };
  return (
    <DashboardContent>
      <Box display='flex' alignItems='center' mb={5}>
        <Typography variant='h4' flexGrow={1}>
          Update user
        </Typography>
        <Button
          onClick={back}
          variant='contained'
          color='inherit'
          startIcon={<Iconify icon='mingcute:arrow-left-fill' />}
        >
          Back
        </Button>
      </Box>
      <FormContainer>
        <Typography variant='h4' gutterBottom align='center'>
          Update User
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                fullWidth
                label='Name'
                name='name'
                value={formData.name}
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name}
                required
                inputProps={{ 'aria-label': 'Name' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                fullWidth
                label='Email'
                name='email'
                type='email'
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
                required
                inputProps={{ 'aria-label': 'Email' }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl fullWidth error={!!errors.factory}>
                <InputLabel id='factory-label'>Factory</InputLabel>
                <Select
                  labelId='factory-label'
                  name='factory'
                  value={formData.factory}
                  onChange={handleSelectChange}
                  required
                  inputProps={{ 'aria-label': 'Factory' }}
                >
                  <MenuItem value='factory1'>Factory 1</MenuItem>
                  <MenuItem value='factory2'>Factory 2</MenuItem>
                  <MenuItem value='factory3'>Factory 3</MenuItem>
                </Select>
                {errors.factory && (
                  <Typography variant='caption' color='error'>
                    {errors.factory}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth error={!!errors.class}>
                <InputLabel id='class-label'>Class</InputLabel>
                <Select
                  labelId='class-label'
                  name='class'
                  value={formData.class}
                  onChange={handleSelectChange}
                  required
                  inputProps={{ 'aria-label': 'Class' }}
                >
                  <MenuItem value='class1'>Class 1</MenuItem>
                  <MenuItem value='class2'>Class 2</MenuItem>
                  <MenuItem value='class3'>Class 3</MenuItem>
                </Select>
                {errors.class && (
                  <Typography variant='caption' color='error'>
                    {errors.class}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth error={!!errors.club}>
                <InputLabel id='club-label'>Club</InputLabel>
                <Select
                  labelId='club-label'
                  name='club'
                  value={formData.club}
                  onChange={handleSelectChange}
                  required
                  inputProps={{ 'aria-label': 'Club' }}
                >
                  <MenuItem value='club1'>Club 1</MenuItem>
                  <MenuItem value='club2'>Club 2</MenuItem>
                  <MenuItem value='club3'>Club 3</MenuItem>
                </Select>
                {errors.club && (
                  <Typography variant='caption' color='error'>
                    {errors.club}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ pt: 1.5, pb: 1.5 }}>
              <input
                accept='image/*'
                style={{ display: 'none', padding: '8px 0' }}
                id='image-upload'
                type='file'
                onChange={handleImageUpload}
              />
              <label htmlFor='image-upload'>
                <Button
                  variant='outlined'
                  component='span'
                  startIcon={<Iconify icon={'mingcute:upload-3-line'} />}
                  fullWidth
                >
                  Upload Profile Image
                </Button>
              </label>
              {formData.image && (
                <ImagePreview src={formData.image} alt='Profile Preview' />
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                fullWidth
                sx={{ pt: 1.5, pb: 1.5 }}
                disabled={loading}
                startIcon={
                  loading && <CircularProgress size={20} color='inherit' />
                }
              >
                {loading ? 'Updating User...' : 'Update User'}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                type='button'
                variant='contained'
                color='primary'
                fullWidth
                sx={{ pt: 1.5, pb: 1.5 }}
                disabled={loading}
                startIcon={
                  loading && <CircularProgress size={20} color='inherit' />
                }
              >
                {loading ? 'Updating User...' : 'Reset password'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormContainer>
    </DashboardContent>
  );
};

export { UserUpdate };
