import { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import App from './app';

const Admin = () => {
  return (
    <HelmetProvider>
      <Suspense fallback>
        <App />
      </Suspense>
    </HelmetProvider>
  );
};

export default Admin;
