import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { bannerApi } from '../apis';
import { Banner } from '../data';

interface BannerState {
  banners: Banner[];
  loading: boolean;
  error: string | null;
}

const initialState: BannerState = {
  banners: [],
  loading: false,
  error: null,
};

export const getBanner = createAsyncThunk('banner/get', async () => {
  const response = await bannerApi.get();
  const data = response.data;
  return data as Banner[];
});

export const addBanner = createAsyncThunk(
  'banner/create',
  async (newBanner: Partial<Banner>) => {
    const response = await bannerApi.create(newBanner);
    const data = await response.data;
    return data as Banner;
  }
);

export const updateBanner = createAsyncThunk(
  'banner/update',
  async (banner: Partial<Banner>) => {
    const response = await bannerApi.updateById(banner._id!, banner);
    const data = await response.data;
    return data as Banner;
  }
);

export const deleteBanner = createAsyncThunk(
  'banner/delete',
  async (id: string) => {
    const response = await bannerApi.deleteById(id);
    return response.data?.message || 'Delete successful!';
  }
);

const bannerSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBanner.pending, (state) => {
        (state.loading = true), (state.error = null);
      })
      .addCase(
        getBanner.fulfilled,
        (state, action: PayloadAction<Banner[]>) => {
          state.loading = false;
          state.banners = action.payload;
        }
      )
      .addCase(getBanner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to get banner!';
      })
      .addCase(addBanner.fulfilled, (state, action: PayloadAction<Banner>) => {
        state.banners.push(action.payload);
      })
      .addCase(
        updateBanner.fulfilled,
        (state, action: PayloadAction<Banner>) => {
          const index = state.banners.findIndex(
            (banner) => banner._id === action.payload._id
          );
          if (index !== -1) state.banners[index] = action.payload;
        }
      )
      .addCase(
        deleteBanner.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.banners = state.banners.filter(
            (banner) => banner._id !== action.payload
          );
        }
      );
  },
});
const bannerReducer = bannerSlice.reducer;
export { bannerReducer };
