export const images = {
    Vietnam: require('./images/vietnam.png'),
    English: require('./images/unitedkingdom.png'),
    HSV: require('./images/HSV.webp'),
    DTN: require('./images/DTN.jpg'),
    MHX: require('./images/MHX.jpg'),
    VAA: require('./images/VAA.jpg'),
    XTN: require('./images/XTN.jpg'),
    slide1: require('./images/slide1.png'),
    slide2: require('./images/slide2.png'),
    slide3: require('./images/slide3.png'),
    slide4: require('./images/slide4.png'),
}