import { useCallback, useState } from 'react';

import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Factory } from '../../../data';
import { Iconify } from '../../components/iconify';

// ----------------------------------------------------------------------

type FactoryTableRowProps = {
  row: Factory;
  selected: boolean;
  onSelectRow: () => void;
};

export function FactoryTableRow({
  row,
  selected,
  onSelectRow,
}: FactoryTableRowProps) {
  const [openPopover, setOpenPopover] = useState<HTMLButtonElement | null>(
    null
  );

  const handleOpenPopover = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setOpenPopover(event.currentTarget);
    },
    []
  );

  const handleClosePopover = useCallback(() => {
    setOpenPopover(null);
  }, []);

  const navigate = useNavigate();
  const handleUpdateFactory = () => navigate(`update-user/${row._id}`);
  const handleDeleteFactory = () => {
    handleClosePopover();
  };

  return (
    <>
      <TableRow hover tabIndex={-1} role='checkbox' selected={selected}>
        <TableCell padding='checkbox'>
          <Checkbox disableRipple checked={selected} onChange={onSelectRow} />
        </TableCell>

        <TableCell>{row.code}</TableCell>

        <TableCell component='th' scope='row'>
          {row.name}
        </TableCell>

        <TableCell component='th' scope='row'>
          {row.description}
        </TableCell>

        <TableCell align='center'>
          {row.active ? (
            <Iconify
              width={22}
              icon='solar:check-circle-bold'
              sx={{ color: 'success.main' }}
            />
          ) : (
            '-'
          )}
        </TableCell>

        <TableCell>{moment(row.createdAt).format('DD/MM/YYYY')}</TableCell>

        <TableCell>{moment(row.updatedAt).format('DD/MM/YYYY')}</TableCell>

        <TableCell align='right'>
          <IconButton onClick={handleOpenPopover}>
            <Iconify icon='eva:more-vertical-fill' />
          </IconButton>
        </TableCell>
      </TableRow>

      <Popover
        open={!!openPopover}
        anchorEl={openPopover}
        onClose={handleClosePopover}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuList
          disablePadding
          sx={{
            p: 0.5,
            gap: 0.5,
            width: 140,
            display: 'flex',
            flexDirection: 'column',
            [`& .${menuItemClasses.root}`]: {
              px: 1,
              gap: 2,
              borderRadius: 0.75,
              [`&.${menuItemClasses.selected}`]: { bgcolor: 'action.selected' },
            },
          }}
        >
          <MenuItem onClick={handleUpdateFactory}>
            <Iconify icon='solar:pen-bold' />
            Edit
          </MenuItem>

          <MenuItem onClick={handleDeleteFactory} sx={{ color: 'error.main' }}>
            <Iconify icon='solar:trash-bin-trash-bold' />
            Delete
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
}
