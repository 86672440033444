import axios from 'axios';

export const BASE_URL_API_ACCOUNT = 'http://localhost:8080/api';

class AccountApi {
  public async login(email: string, password: string) {
    const response = await axios.post(
      `${BASE_URL_API_ACCOUNT}/login`,
      new URLSearchParams({
        email: email,
        password: password,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    return response.data;
  }
}

const accountApi = new AccountApi();
export { accountApi };
