import { useTranslation } from 'react-i18next';
import LandingPage from './LandingPage';

const StudentOf5Merits = () => {
  const { t } = useTranslation();
  document.title = t('menuStudentOf5Merits');

  return (
    <>
      <LandingPage>
        <div>Hello world!</div>
      </LandingPage>
    </>
  );
};

export default StudentOf5Merits;
