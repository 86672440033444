import { useTranslation } from 'react-i18next';
import LandingPage from './LandingPage';

const News = () => {
  const { t } = useTranslation();
  document.title = t('menuNews');

  return (
    <>
      <LandingPage>
        <div>Hello world!</div>
      </LandingPage>
    </>
  );
};

export default News;
