import { axiosConfigs } from '.';
import { Factory } from '../data';

const BASE_API = '/factory';
class FactoryApi {
  get = async () => {
    return await axiosConfigs.get(BASE_API);
  };

  getById = async (id: string) => {
    return await axiosConfigs.get(`${BASE_API}/${id}`);
  };
  create = async (newFactory: Partial<Factory>) => {
    return await axiosConfigs.post(`${BASE_API}`, newFactory);
  };
  updateById = async (id: string, factory: Partial<Factory>) => {
    return await axiosConfigs.put(`${BASE_API}/${id}`, factory);
  };
  deleteById = async (id: string) => {
    return await axiosConfigs.delete(`${BASE_API}/${id}`);
  };
}

const factoryApi = new FactoryApi();
export { factoryApi };
