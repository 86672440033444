import { User } from '../data';
import { axiosConfigs } from './';

const BASE_API = '/user';
class UserApi {
  get = async () => {
    return await axiosConfigs.get(BASE_API);
  };

  getById = async (id: string) => {
    return await axiosConfigs.get(`${BASE_API}/${id}`);
  };
  create = async (newUser: User) => {
    return await axiosConfigs.post(`${BASE_API}`, newUser);
  };
  updateById = async (id: string, user: User) => {
    return await axiosConfigs.put(`${BASE_API}/${id}`, user);
  };
  deleteById = async (id: string) => {
    return await axiosConfigs.delete(`${BASE_API}/${id}`);
  };
}

const userApi = new UserApi();
export { userApi };
