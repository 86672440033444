import { Navigate, Outlet } from 'react-router-dom';
import { AppRoute } from '../constant';
import { jwtDecode } from 'jwt-decode';

export const ProtectedRoute = () => {
  if(localStorage.getItem('token')) {
    const token = jwtDecode(localStorage.getItem('token') as string);
    return token && token.exp as number > new Date().getTime() / 1000  
    ? <Outlet /> : <Navigate to={AppRoute.LOGIN} />;
  } else return <Navigate to={AppRoute.LOGIN} />
};
