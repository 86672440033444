import { useCallback, useState } from 'react';

import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { History } from '../../../data';
import { Iconify } from '../../components/iconify';
import moment from 'moment';
import { Chip } from '@mui/material';

// ----------------------------------------------------------------------

type HistoryTableRowProps = {
  row: History;
  selected: boolean;
  onSelectRow: () => void;
};

export function HistoryTableRow({
  row,
  selected,
  onSelectRow,
}: HistoryTableRowProps) {
  const [openPopover, setOpenPopover] = useState<HTMLButtonElement | null>(
    null
  );

  const handleClosePopover = useCallback(() => {
    setOpenPopover(null);
  }, []);

  return (
    <>
      <TableRow hover tabIndex={-1} role='checkbox' selected={selected}>
        <TableCell padding='checkbox'>
          <Checkbox disableRipple checked={selected} onChange={onSelectRow} />
        </TableCell>

        <TableCell component='th' scope='row'>
          {row.action}
        </TableCell>

        <TableCell>
          {row.action === 'Login' ? (
            <Chip variant='filled' color='info' label={row.action} />
          ) : row.action === 'Logout' ? (
            <Chip variant='filled' color='default' label={row.action} />
          ) : row.action === 'Create' ? (
            <Chip variant='filled' color='success' label={row.action} />
          ) : row.action === 'Update' ? (
            <Chip variant='filled' color='warning' label={row.action} />
          ) : (
            <Chip variant='filled' color='error' label={row.action} />
          )}
        </TableCell>

        <TableCell>{row.detail}</TableCell>

        <TableCell>
          {moment(row.createdAt).format('HH:mm DD/MM/YYYY')}
        </TableCell>
      </TableRow>

      <Popover
        open={!!openPopover}
        anchorEl={openPopover}
        onClose={handleClosePopover}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuList
          disablePadding
          sx={{
            p: 0.5,
            gap: 0.5,
            width: 140,
            display: 'flex',
            flexDirection: 'column',
            [`& .${menuItemClasses.root}`]: {
              px: 1,
              gap: 2,
              borderRadius: 0.75,
              [`&.${menuItemClasses.selected}`]: { bgcolor: 'action.selected' },
            },
          }}
        >
          <MenuItem onClick={() => {}}>
            <Iconify icon='solar:pen-bold' />
            Edit
          </MenuItem>

          <MenuItem onClick={() => {}} sx={{ color: 'error.main' }}>
            <Iconify icon='solar:trash-bin-trash-bold' />
            Delete
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
}
