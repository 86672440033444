import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { categoryApi } from '../apis';
import { Category } from '../data';

interface CategoryState {
  categories: Category[];
  loading: boolean;
  error: string | null;
}

const initialState: CategoryState = {
  categories: [],
  loading: false,
  error: null,
};

export const getCategory = createAsyncThunk('category/get', async () => {
  const response = await categoryApi.get();
  const data = response.data;
  return data as Category[];
});

export const addCategory = createAsyncThunk(
  'category/create',
  async (newCategory: Partial<Category>) => {
    const response = await categoryApi.create(newCategory);
    const data = await response.data;
    return data as Category;
  }
);

export const updateCategory = createAsyncThunk(
  'category/update',
  async (category: Partial<Category>) => {
    const response = await categoryApi.updateById(category._id!, category);
    const data = await response.data;
    return data as Category;
  }
);

export const deleteCategory = createAsyncThunk(
  'category/delete',
  async (id: string) => {
    const response = await categoryApi.deleteById(id);
    return response.data?.message || 'Delete successful!';
  }
);

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategory.pending, (state) => {
        (state.loading = true), (state.error = null);
      })
      .addCase(
        getCategory.fulfilled,
        (state, action: PayloadAction<Category[]>) => {
          state.loading = false;
          state.categories = action.payload;
        }
      )
      .addCase(getCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to get category!';
      })
      .addCase(
        addCategory.fulfilled,
        (state, action: PayloadAction<Category>) => {
          state.categories.push(action.payload);
        }
      )
      .addCase(
        updateCategory.fulfilled,
        (state, action: PayloadAction<Category>) => {
          const index = state.categories.findIndex(
            (category) => category._id === action.payload._id
          );
          if (index !== -1) state.categories[index] = action.payload;
        }
      )
      .addCase(
        deleteCategory.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.categories = state.categories.filter(
            (category) => category._id !== action.payload
          );
        }
      );
  },
});
const categoryReducer = categorySlice.reducer;
export { categoryReducer };
