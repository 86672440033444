import ResponsiveAppBar from "../components/appbar/ResponsiveAppBar";
import ResponsiveFooter from '../components/footer/Footer';
import React from "react";
type Props = {
  children: any;
};
export default function LandingPage(props: Props) {
  const { children } = props;
  return (
    <>
      <ResponsiveAppBar />
      {children}
      <ResponsiveFooter />
    </>
  );
}
