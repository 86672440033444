import { Editor } from '@tiptap/react';
import Figcaption from '../admin/extensions/figacaption';
import ImageBlock from '../admin/extensions/ImageBlock/ImageBlock';
import Link from '../admin/extensions/link';
import { TableOfContentsNode } from '../admin/extensions/table-of-content-node';

export const isTableGripSelected = (node: HTMLElement) => {
  let container = node;

  while (container && !['TD', 'TH'].includes(container.tagName)) {
    container = container.parentElement!;
  }

  const gripColumn =
    container &&
    container.querySelector &&
    container.querySelector('a.grip-column.selected');
  const gripRow =
    container &&
    container.querySelector &&
    container.querySelector('a.grip-row.selected');

  if (gripColumn || gripRow) {
    return true;
  }

  return false;
};

export const isCustomNodeSelected = (editor: Editor, node: HTMLElement) => {
  const customNodes = [
    TableOfContentsNode.name,
    ImageBlock.name,
    Figcaption.name,
    Link.name,
  ];

  return (
    customNodes.some((type) => editor.isActive(type)) ||
    isTableGripSelected(node)
  );
};

export default isCustomNodeSelected;
