import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clubApi } from '../apis';
import { Club } from '../data';

interface ClubState {
  clubs: Club[];
  loading: boolean;
  error: string | null;
}

const initialState: ClubState = {
  clubs: [],
  loading: false,
  error: null,
};

export const getClub = createAsyncThunk('club/get', async () => {
  const response = await clubApi.get();
  const data = response.data;
  return data as Club[];
});

export const addClub = createAsyncThunk(
  'club/create',
  async (newClub: Partial<Club>) => {
    const response = await clubApi.create(newClub);
    const data = await response.data;
    return data as Club;
  }
);

export const updateClub = createAsyncThunk(
  'club/update',
  async (club: Partial<Club>) => {
    const response = await clubApi.updateById(club._id!, club);
    const data = await response.data;
    return data as Club;
  }
);

export const deleteClub = createAsyncThunk(
  'club/delete',
  async (id: string) => {
    const response = await clubApi.deleteById(id);
    return response.data?.message || 'Delete successful!';
  }
);

const clubSlice = createSlice({
  name: 'club',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getClub.pending, (state) => {
        (state.loading = true), (state.error = null);
      })
      .addCase(getClub.fulfilled, (state, action: PayloadAction<Club[]>) => {
        state.loading = false;
        state.clubs = action.payload;
      })
      .addCase(getClub.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to get club!';
      })
      .addCase(addClub.fulfilled, (state, action: PayloadAction<Club>) => {
        state.clubs.push(action.payload);
      })
      .addCase(updateClub.fulfilled, (state, action: PayloadAction<Club>) => {
        const index = state.clubs.findIndex(
          (club) => club._id === action.payload._id
        );
        if (index !== -1) state.clubs[index] = action.payload;
      })
      .addCase(deleteClub.fulfilled, (state, action: PayloadAction<string>) => {
        state.clubs = state.clubs.filter((club) => club._id !== action.payload);
      });
  },
});
const clubReducer = clubSlice.reducer;
export { clubReducer };
