import React from "react";
import LandingPage from "./LandingPage";
import { useTranslation } from "react-i18next";

const GoodStories = () => {
  const { t } = useTranslation();
  document.title = t("menuGoodStories")

  return (
    <>
      <LandingPage>
        <div>GoodStories</div>
      </LandingPage>
    </>
  );
};

export default GoodStories;
