import React from "react";
import SignIn from '../components/login/SignIn';
import { useTranslation } from 'react-i18next';

const SignInPage = () => {
  const { t } = useTranslation();
  document.title = t('login');
  return <SignIn />;
};

export default SignInPage;
