interface IEnvironmentConfig {
  [key: string]: {
    apiUrl: string;
    baseUrl: string;
  };
}

const EnvironmentConfig: IEnvironmentConfig = {
  local: {
    apiUrl: 'http://localhost:8080/api',
    baseUrl: 'http://localhost:8080/',
  },

  development: {
    apiUrl: 'http://localhost:8080/api',
    baseUrl: 'http://localhost:8080/',
  },

  production: {
    apiUrl: 'http://localhost:8080/api',
    baseUrl: 'http://localhost:8080/',
  },
};

export const getConfig = () => {
  const env = process.env.NODE_ENV || 'local';

  return EnvironmentConfig[env];
};
