import { Student } from '../data';
import {axiosConfigs} from '.';

const BASE_API = '/student';
class StudentApi {
  get = async () => {
    return await axiosConfigs.get(BASE_API);
  };

  getById = async (id: string) => {
    return await axiosConfigs.get(`${BASE_API}/${id}`);
  };
  create = async (newStudent: Partial<Student>) => {
    return await axiosConfigs.post(`${BASE_API}`, newStudent);
  };
  createMany = async (newStudents: Partial<Student>[]) => {
    return await axiosConfigs.post(`${BASE_API}`, newStudents);
  };
  updateById = async (id: string, student: Partial<Student>) => {
    return await axiosConfigs.put(`${BASE_API}/${id}`, student);
  };
  deleteById = async (id: string) => {
    return await axiosConfigs.delete(`${BASE_API}/${id}`);
  };
}

const studentApi = new StudentApi();
export { studentApi };
