import { axiosConfigs } from '.';
import { Class } from '../data';

const BASE_API = '/class';
class ClassApi {
  get = async () => {
    return await axiosConfigs.get(BASE_API);
  };

  getById = async (id: string) => {
    return await axiosConfigs.get(`${BASE_API}/${id}`);
  };
  create = async (newClass: Partial<Class>) => {
    return await axiosConfigs.post(`${BASE_API}`, newClass);
  };
  updateById = async (id: string, className: Partial<Class>) => {
    return await axiosConfigs.put(`${BASE_API}/${id}`, className);
  };
  deleteById = async (id: string) => {
    return await axiosConfigs.delete(`${BASE_API}/${id}`);
  };
}

const classApi = new ClassApi();
export { classApi };
