import axios, { AxiosRequestConfig } from 'axios';
import { setLastHref } from '../utils';
import { USER_TOKEN } from '../utils/constants';

// example
// let baseUrl = "https://hsv.vaa.edu.vn";
let baseUrl = 'http://localhost:8080/api';

const options: AxiosRequestConfig = {
  baseURL: `${baseUrl}`,
  headers: {
    'Content-Type': 'application/json',
  },
};

const token = JSON.parse(localStorage.getItem(USER_TOKEN) as string);

const axiosConfigs = axios.create(options);

if (token) {
  axiosConfigs.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

axiosConfigs.interceptors.request.use(
  function (request) {
    if (request.params) {
      for (const key of Object.keys(request.params)) {
        if (!request.params[key]) {
          // check null, undefined and ""
          delete request.params[key];
        }
      }
    }
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosConfigs.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error && error.response) {
      if (error.response.status === 403)
        return Promise.reject("You don't have permision!");
      else if (error.response.status === 401) {
        const lastHref = location.href.replace(location.origin, '');
        setLastHref(lastHref);
        return Promise.reject('Please login to use!');
      }
    }
  }
);

export { axiosConfigs };
