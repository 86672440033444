import { useTranslation } from 'react-i18next';
import LandingPage from './LandingPage';
const SupportStudent = () => {
  const { t } = useTranslation();
  document.title = t('menuSupportStudent');

  return (
    <>
      <LandingPage>
        <div>hỗ trợ sv</div>
      </LandingPage>
    </>
  );
};

export default SupportStudent;
