import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../store/index';
import { getLastHref } from '../utils';

export default function AuthenticatedRoute(): React.ReactElement {
  const { user } = useAppSelector((state) => state.auth);
  const getRoute = () => {
    const lastHref = getLastHref();
    if (lastHref && lastHref !== '/') {
      return lastHref;
    } else {
      return '/';
    }
  };

  return user?.id ? <Navigate to={getRoute()} /> : <Outlet />;
}
