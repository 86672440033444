import { FileHandler } from '@tiptap-pro/extension-file-handler';
import { Document as TipTapDocument } from '@tiptap/extension-document';
import Dropcursor from '@tiptap/extension-dropcursor';
import { FocusClasses as Focus } from '@tiptap/extension-focus';
import Link from '@tiptap/extension-link';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Typography from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';

import './editor.css';
import './image.css';

import TableOfContents from '@tiptap-pro/extension-table-of-contents';
import TiptapHeading, { Level } from '@tiptap/extension-heading';
import Placeholder from '@tiptap/extension-placeholder';
import { EditorContent, mergeAttributes, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import 'iframe-resizer/js/iframeResizer.contentWindow';
import { useRef } from 'react';
import BlockquoteFigure from '../../extensions/BlockquoteFigure/block-quote-figure';
import ImageBlockMenu from '../../extensions/ImageBlock/components/ImageBlockMenu';
import ImageBlock from '../../extensions/ImageBlock/ImageBlock';
import ImageUpload from '../../extensions/ImageUpload/ImageUpload';
import { TableOfContentsNode } from '../../extensions/table-of-content-node';
import { useSidebar } from '../../hooks/use-side-bar';
import { ContentItemMenu } from '../menus/ContentItemMenu/content-item-menu';
import { TextMenu } from '../menus/text-menu/text-menu';
import SlashCommand from '../SlashCommand/slash-command';
import { EditorHeader } from './editor-header';
import { Sidebar } from './side-bar';

export const BlockEditor = ({ aiToken }: { aiToken?: string }) => {
  const menuContainerRef = useRef(null);
  const leftSidebar = useSidebar();
  const editor = useEditor({
    extensions: [
      TipTapDocument,
      Text,
      Paragraph,
      // TaskList,
      // TaskItem.configure({
      //   nested: true,
      // }),
      TiptapHeading.extend({
        renderHTML({ node, HTMLAttributes }) {
          const nodeLevel = parseInt(node.attrs.level, 10) as Level;
          const hasLevel = this.options.levels.includes(nodeLevel);
          const level = hasLevel ? nodeLevel : this.options.levels[0];
          return [
            `h${level}`,
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
            0,
          ];
        },
      }),
      // TiptapHorizontalRule.extend({
      //   renderHTML() {
      //     return [
      //       'div',
      //       mergeAttributes(this.options.HTMLAttributes, {
      //         'data-type': this.name,
      //       }),
      //       ['hr'],
      //     ];
      //   },
      // }),
      StarterKit.configure({
        document: false,
        dropcursor: false,
        heading: false,
        horizontalRule: false,
        blockquote: false,
        history: false,
        codeBlock: false,
      }),
      // Details.configure({
      //   persist: true,
      //   HTMLAttributes: {
      //     class: 'details',
      //   },
      // }),
      // DetailsContent,
      // DetailsSummary,
      SlashCommand,
      TextStyle,
      // FontSize,
      // FontFamily,
      // Color,
      // TrailingNode,
      Link.configure({
        openOnClick: false,
      }),
      // Highlight.configure({ multicolor: true }),
      Underline,
      // CharacterCount.configure({ limit: 50000 }),
      TableOfContents,
      TableOfContentsNode,

      FileHandler.configure({
        allowedMimeTypes: [
          'image/png',
          'image/jpeg',
          'image/gif',
          'image/webp',
        ],
        onDrop: (currentEditor, files, pos) => {
          files.forEach(async (file) => {
            // const url = await API.uploadImage(file)
            // currentEditor.chain().setImageBlockAt({ pos, src: url }).focus().run()
          });
        },
        onPaste: (currentEditor, files, htmlContent) => {
          files.forEach(async (file) => {
            if (htmlContent) {
              return false;
            }
            // const url = await API.uploadImage(file)
            // handle upload image here
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
              currentEditor
                .chain()
                .setImageBlockAt({
                  pos: currentEditor.state.selection.anchor,
                  src: fileReader.result as string,
                })
                .focus()
                .run();
            };
          });
        },
      }),
      // Emoji.configure({
      //   enableEmoticons: true,
      // }),
      TextAlign.extend({
        addKeyboardShortcuts() {
          return {};
        },
      }).configure({
        types: ['heading', 'paragraph'],
      }),
      // Subscript,
      // Superscript,
      ImageBlock,
      ImageUpload,
      Typography,
      Placeholder.configure({
        includeChildren: true,
        showOnlyCurrent: false,
        placeholder: () => '',
      }),
      Focus,
      BlockquoteFigure,
      Dropcursor.configure({
        width: 2,
        class: 'ProseMirror-dropcursor border-black',
      }),
    ],
    content: '',
    autofocus: true,
    onUpdate: ({ editor }) => {
      // console.log(editor.getJSON());
    },
    editorProps: {
      attributes: {
        autocomplete: 'off',
        autocorrect: 'off',
        autocapitalize: 'off',
        class: 'min-h-full',
      },
      editable: () => {
        return !leftSidebar.isOpen;
      },
    },
  });

  if (editor === null) {
    return null;
  }

  return (
    <main className='flex flex-col h-screen'>
      <div className='flex h-full' ref={menuContainerRef}>
        <Sidebar
          isOpen={leftSidebar.isOpen}
          onClose={leftSidebar.close}
          editor={editor}
        />
        <div className='relative flex flex-col flex-1 h-full overflow-hidden'>
          <EditorHeader
            editor={editor}
            isSidebarOpen={leftSidebar.isOpen}
            toggleSidebar={leftSidebar.toggle}
          />
          <EditorContent
            editor={editor}
            className='flex-1 overflow-y-auto px-10'
          />
          <TextMenu editor={editor} />
          <ContentItemMenu editor={editor} />
          <ImageBlockMenu editor={editor} appendTo={menuContainerRef} />
        </div>
      </div>
    </main>
  );
};
