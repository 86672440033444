import {
  Box,
  Button,
  TableBody,
  TablePagination,
  Typography,
} from '@mui/material';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AcademicYear } from '../../../../data';
import { getAcademicYear } from '../../../../feature';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { Iconify } from '../../../components/iconify';
import { Scrollbar } from '../../../components/scrollbar';
import { DashboardContent } from '../../../layouts/dashboard';
import { TableEmptyRows, TableNoData } from '../../table';
import { AcademicYearTableHead } from '../academic-table-head';
import { AcademicYearTableRow } from '../academic-table-row';
import { AcademicYearTableToolbar } from '../academic-table-toolbar';
import { applyFilter, emptyRows, getComparator } from '../utils';
const AcademicView = () => {
  const table = useTable();
  const [filterName, setFilterName] = useState('');
  const { academicYear } = useAppSelector((state) => state.academicYear);
  const dispatch = useAppDispatch();
  console.log(academicYear);
  useEffect(() => {
    // if (!academicYear || academicYear.length === 0)
    dispatch(getAcademicYear());
  }, []);

  const dataFiltered: AcademicYear[] = applyFilter({
    inputData: academicYear,
    comparator: getComparator(table.order, table.orderBy),
    filterName,
  });

  const notFound = !dataFiltered.length && !!filterName;

  const navigate = useNavigate();
  const createNewAcademic = () => navigate('create');

  return (
    <DashboardContent maxWidth='xl'>
      <Box display='flex' alignItems='center' mb={5}>
        <Typography variant='h4' flexGrow={1}>
          Academic year
        </Typography>
        <Button
          onClick={createNewAcademic}
          variant='contained'
          color='inherit'
          startIcon={<Iconify icon='mingcute:add-line' />}
        >
          New academic year
        </Button>
      </Box>

      <Card>
        <AcademicYearTableToolbar
          numSelected={table.selected.length}
          filterName={filterName}
          onFilterName={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFilterName(event.target.value);
            table.onResetPage();
          }}
        />

        <Scrollbar>
          <TableContainer sx={{ overflow: 'unset' }}>
            <Table sx={{ minWidth: 800 }}>
              <AcademicYearTableHead
                order={table.order}
                orderBy={table.orderBy}
                rowCount={academicYear.length}
                numSelected={table.selected.length}
                onSort={table.onSort}
                onSelectAllRows={(checked) =>
                  table.onSelectAllRows(
                    checked,
                    academicYear.map((academic) => academic._id)
                  )
                }
                headLabel={[
                  { id: 'code', label: 'Code' },
                  { id: 'name', label: 'Name' },
                  { id: 'description', label: 'Description' },
                  { id: 'from', label: 'From' },
                  { id: 'to', label: 'To' },
                  { id: 'active', label: 'Active' },
                  { id: 'createdAt', label: 'Create at' },
                  { id: 'updatedAt', label: 'Update at' },
                  { id: '_id', label: 'Action' },
                ]}
              />
              <TableBody>
                {dataFiltered
                  .slice(
                    table.page * table.rowsPerPage,
                    table.page * table.rowsPerPage + table.rowsPerPage
                  )
                  .map((row) => (
                    <AcademicYearTableRow
                      key={row._id}
                      row={row}
                      selected={table.selected.includes(row._id)}
                      onSelectRow={() => table.onSelectRow(row._id)}
                    />
                  ))}

                <TableEmptyRows
                  height={68}
                  emptyRows={emptyRows(
                    table.page,
                    table.rowsPerPage,
                    academicYear.length
                  )}
                />

                {notFound && <TableNoData searchQuery={filterName} />}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          component='div'
          page={table.page}
          count={academicYear.length}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={table.onChangeRowsPerPage}
        />
      </Card>
    </DashboardContent>
  );
};

export { AcademicView };

export function useTable() {
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selected, setSelected] = useState<string[]>([]);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');

  const onSort = useCallback(
    (id: string) => {
      const isAsc = orderBy === id && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    },
    [order, orderBy]
  );

  const onSelectAllRows = useCallback(
    (checked: boolean, newSelecteds: string[]) => {
      if (checked) {
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    },
    []
  );

  const onSelectRow = useCallback(
    (inputValue: string) => {
      const newSelected = selected.includes(inputValue)
        ? selected.filter((value) => value !== inputValue)
        : [...selected, inputValue];

      setSelected(newSelected);
    },
    [selected]
  );

  const onResetPage = useCallback(() => {
    setPage(0);
  }, []);

  const onChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const onChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      onResetPage();
    },
    [onResetPage]
  );

  return {
    page,
    order,
    onSort,
    orderBy,
    selected,
    rowsPerPage,
    onSelectRow,
    onResetPage,
    onChangePage,
    onSelectAllRows,
    onChangeRowsPerPage,
  };
}
