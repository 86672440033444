import { axiosConfigs } from '.';
import { History } from '../data';

const BASE_API = '/history';
class HistoryApi {
  get = async () => {
    return await axiosConfigs.get(BASE_API);
  };

  create = async (newHistory: Partial<History>) => {
    return await axiosConfigs.post(`${BASE_API}`, newHistory, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };
}

const historyApi = new HistoryApi();
export { historyApi };
