import { axiosConfigs } from '.';
import { Banner } from '../data';

const BASE_API = '/banner';
class BannerApi {
  get = async () => {
    return await axiosConfigs.get(BASE_API);
  };

  getById = async (id: string) => {
    return await axiosConfigs.get(`${BASE_API}/${id}`);
  };
  create = async (newBanner: Partial<Banner>) => {
    return await axiosConfigs.post(`${BASE_API}`, newBanner);
  };
  updateById = async (id: string, banner: Partial<Banner>) => {
    return await axiosConfigs.put(`${BASE_API}/${id}`, banner);
  };
  deleteById = async (id: string) => {
    return await axiosConfigs.delete(`${BASE_API}/${id}`);
  };
}

const bannerApi = new BannerApi();
export { bannerApi };
