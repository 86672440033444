import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Iconify } from '../../../components/iconify';
import { DashboardContent } from '../../../layouts/dashboard';

const AcademicCreate = () => {
  const navigate = useNavigate();
  const handleClickBack = () => navigate('/admin/academic-year');
  return (
    <DashboardContent>
      <Box display='flex' alignItems='center' mb={5}>
        <Typography variant='h4' flexGrow={1}>
          Create new Academic year
        </Typography>
        <Button
          onClick={handleClickBack}
          variant='contained'
          color='inherit'
          startIcon={<Iconify icon='mingcute:arrow-left-fill' />}
        >
          Back
        </Button>
      </Box>
    </DashboardContent>
  );
};

export { AcademicCreate };
