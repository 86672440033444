export enum AppRoute {
  NEWS = '/news',
  LOGIN = '/login',
  ADMIN = '/admin/*',
  ROOT = '/',
  NOT_FOUND = '/*',
  CLUB = '/club',
  STUDENT_OF_5_MERITS = '/studento5m',
  SUPPORT_STUDENT = '/support',
  INTRODUCE = '/introduce',
  HCMCYoU = '/HCMCYoU',
  EMAIL = '/email',
  ONLINE_OFFICE = '/online-office',
  GOOD_STORIES = '/good-stories',
}
