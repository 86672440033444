import { axiosConfigs } from '.';
import { Blog } from '../data';

const BASE_API = '/news';
class BlogApi {
  get = async () => {
    return await axiosConfigs.get(BASE_API);
  };

  getById = async (id: string) => {
    return await axiosConfigs.get(`${BASE_API}/${id}`);
  };
  create = async (newBlog: Partial<Blog>) => {
    return await axiosConfigs.post(`${BASE_API}`, newBlog);
  };
  updateById = async (id: string, news: Partial<Blog>) => {
    return await axiosConfigs.put(`${BASE_API}/${id}`, news);
  };
  deleteById = async (id: string) => {
    return await axiosConfigs.delete(`${BASE_API}/${id}`);
  };
}

const newsApi = new BlogApi();
export { newsApi };
