import { axiosConfigs } from '../apis';

type TypeUploadImage = 'content' | 'blog';

export const UploadImage = async (
  type: TypeUploadImage,
  file: File
): Promise<string> => {
  const formData = new FormData();
  formData.append('image', file);
  formData.append('type', type);

  const response = await axiosConfigs.put('/image', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data.src;
};
