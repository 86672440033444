import { Box, Button, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Student } from '../../../../data';
import { getStudent } from '../../../../feature';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { Iconify } from '../../../components/iconify';
import { DashboardContent } from '../../../layouts/dashboard';
import { applyFilter, getComparator } from '../../student/utils';
const StudentView = () => {
  const navigate = useNavigate();
  const createNewStudent = () => navigate('create');
  const table = useTable();
  const [filterName, setFilterName] = useState('');
  const { students } = useAppSelector((state) => state.student);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!students || students.length === 0) dispatch(getStudent());
  }, [dispatch, students]);

  const dataFiltered: Student[] = applyFilter({
    inputData: students,
    comparator: getComparator(table.order, table.orderBy),
    filterName,
  });

  const notFound = !dataFiltered.length && !!filterName;
  return (
    <DashboardContent maxWidth='xl'>
      <Box display='flex' alignItems='center' mb={5}>
        <Typography variant='h4' flexGrow={1}>
          Student
        </Typography>
        <Button
          onClick={createNewStudent}
          variant='contained'
          color='inherit'
          startIcon={<Iconify icon='mingcute:add-line' />}
        >
          New Student
        </Button>
      </Box>
    </DashboardContent>
  );
};

export { StudentView };
export function useTable() {
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selected, setSelected] = useState<string[]>([]);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');

  const onSort = useCallback(
    (id: string) => {
      const isAsc = orderBy === id && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    },
    [order, orderBy]
  );

  const onSelectAllRows = useCallback(
    (checked: boolean, newSelecteds: string[]) => {
      if (checked) {
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    },
    []
  );

  const onSelectRow = useCallback(
    (inputValue: string) => {
      const newSelected = selected.includes(inputValue)
        ? selected.filter((value) => value !== inputValue)
        : [...selected, inputValue];

      setSelected(newSelected);
    },
    [selected]
  );

  const onResetPage = useCallback(() => {
    setPage(0);
  }, []);

  const onChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const onChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      onResetPage();
    },
    [onResetPage]
  );

  return {
    page,
    order,
    onSort,
    orderBy,
    selected,
    rowsPerPage,
    onSelectRow,
    onResetPage,
    onChangePage,
    onSelectAllRows,
    onChangeRowsPerPage,
  };
}
