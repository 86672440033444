import LandingPage from "./LandingPage";
import { useTranslation } from "react-i18next";
const OnlineOffice = () => {
  const { t } = useTranslation();
  document.title = t('onlineOffice');

  return (
    <>
      <LandingPage>
        <h2>{t('onlineOffice')}</h2>
      </LandingPage>
    </>
  );
};

export default OnlineOffice;
