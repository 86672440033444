import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { classApi } from '../apis';
import { Class } from '../data';

interface ClassState {
  classes: Class[];
  loading: boolean;
  error: string | null;
}

const initialState: ClassState = {
  classes: [],
  loading: false,
  error: null,
};

export const getClass = createAsyncThunk('class/get', async () => {
  const response = await classApi.get();
  const data = response.data;
  return data as Class[];
});

export const addClass = createAsyncThunk(
  'class/create',
  async (newClass: Partial<Class>) => {
    const response = await classApi.create(newClass);
    const data = await response.data;
    return data as Class;
  }
);

export const updateClass = createAsyncThunk(
  'class/update',
  async (updateClass: Partial<Class>) => {
    const response = await classApi.updateById(updateClass._id!, updateClass);
    const data = await response.data;
    return data as Class;
  }
);

export const deleteClass = createAsyncThunk(
  'class/delete',
  async (id: string) => {
    const response = await classApi.deleteById(id);
    return response.data?.message || 'Delete successful!';
  }
);

const classSlice = createSlice({
  name: 'class',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getClass.pending, (state) => {
        (state.loading = true), (state.error = null);
      })
      .addCase(getClass.fulfilled, (state, action: PayloadAction<Class[]>) => {
        state.loading = false;
        state.classes = action.payload;
      })
      .addCase(getClass.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to get class!';
      })
      .addCase(addClass.fulfilled, (state, action: PayloadAction<Class>) => {
        state.classes.push(action.payload);
      })
      .addCase(updateClass.fulfilled, (state, action: PayloadAction<Class>) => {
        const index = state.classes.findIndex(
          (item) => item._id === action.payload._id
        );
        if (index !== -1) state.classes[index] = action.payload;
      })
      .addCase(
        deleteClass.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.classes = state.classes.filter(
            (item) => item._id !== action.payload
          );
        }
      );
  },
});
const classReducer = classSlice.reducer;
export { classReducer };
