import { jwtDecode } from 'jwt-decode';
import { Navigate } from 'react-router-dom';
import { TokenDecode } from '../../data';
import { USER_TOKEN } from '../../utils/constants';

interface Props {
  children?: any;
  redirectTo?: any;
}
const RequireAuth = (props: Props) => {
  const { children, redirectTo } = props;

  let isAuthenticated = true;

  const token = localStorage.getItem(USER_TOKEN) as string;

  if (!token) isAuthenticated = false;
  const userToken = jwtDecode(token) as TokenDecode;

  if (userToken) {
    if (userToken.exp < new Date().getTime() / 1000) isAuthenticated = false;
  } else isAuthenticated = false;
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
};

export default RequireAuth;
