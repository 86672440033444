import { Label } from "../components/label";
import { SvgColor } from "../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor
    width="100%"
    height="100%"
    src={`/assets/icons/navbar/${name}.svg`}
  />
);

export const navData = [
  {
    title: 'Dashboard',
    path: '/admin',
    icon: icon('ic-analytics'),
  },
  {
    title: 'Academic year',
    path: '/admin/academic-year',
    icon: icon('ic-academic'),
  },
  {
    title: 'Banner',
    path: '/admin/banner',
    icon: icon('ic-banner'),
  },
  {
    title: 'Blog',
    path: '/admin/blog',
    icon: icon('ic-blog'),
  },

  {
    title: 'Category',
    path: '/admin/category',
    icon: icon('ic-category'),
  },
  {
    title: 'Class',
    path: '/admin/class',
    icon: icon('ic-class'),
  },
  {
    title: 'Club',
    path: '/admin/club',
    icon: icon('ic-club'),
  },
  {
    title: 'Factory',
    path: '/admin/factory',
    icon: icon('ic-factory'),
  },
  {
    title: 'History',
    path: '/admin/history',
    icon: icon('ic-history'),
  },
  {
    title: 'User',
    path: '/admin/user',
    icon: icon('ic-user'),
  },
  {
    title: 'Product',
    path: '/admin/products',
    icon: icon('ic-cart'),
    info: (
      <Label color='error' variant='inverted'>
        +3
      </Label>
    ),
  },

  {
    title: 'Student',
    path: '/admin/student',
    icon: icon('ic-user'),
  },
  {
    title: 'Not found',
    path: '/admin/404',
    icon: icon('ic-disabled'),
  },
];
