import { axiosConfigs } from '.';
import { Category } from '../data';

const BASE_API = '/category';
class ategoryApi {
  get = async () => {
    return await axiosConfigs.get(BASE_API);
  };

  getById = async (id: string) => {
    return await axiosConfigs.get(`${BASE_API}/${id}`);
  };
  create = async (newCategory: Partial<Category>) => {
    return await axiosConfigs.post(`${BASE_API}`, newCategory);
  };
  updateById = async (id: string, category: Partial<Category>) => {
    return await axiosConfigs.put(`${BASE_API}/${id}`, category);
  };
  deleteById = async (id: string) => {
    return await axiosConfigs.delete(`${BASE_API}/${id}`);
  };
}

const categoryApi = new ategoryApi();
export { categoryApi };
