import { jwtDecode } from "jwt-decode";
import { USER_TOKEN } from "../utils/constants";
import { TokenDecode } from '../data';

export const validateLogin = () => {
  let userTokenString = localStorage.getItem(USER_TOKEN) as string;
  if (userTokenString) {
    let userToken = jwtDecode(userTokenString) as TokenDecode;
    if (userToken && userToken.exp > new Date().getTime() / 1000) {
      window.location.replace("/admin");
    } else {
      localStorage.removeItem('token')
      window.location.replace("/login")
    }
  }
};
