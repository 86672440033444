// ----------------------------------------------------------------------
export const _workspaces = [
  {
    id: 'team-1',
    name: 'Hội sinh viên',
    logo: `/assets/images/HSV.webp`,
    // plan: 'Free',
  },
  {
    id: 'team-2',
    name: 'Đoàn thanh niên',
    logo: `/assets/images/DTN.png`,
    // plan: 'Pro',
  },
  // {
  //   id: 'team-3',
  //   name: 'Team 3',
  //   logo: `/assets/icons/workspaces/logo-3.webp`,
  //   plan: 'Pro',
  // },
];
