import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LANGUAGE } from '../services/constants';
import EN from './en/translation.json';
import VI from './vi/translation.json';

const resources = {
  en: { translation: EN },
  vi: { translation: VI },
};
const language = localStorage.getItem(LANGUAGE);
i18next.use(initReactI18next).init({
  lng: language || 'vi',
  debug: true,
  resources,
});
