import { memo } from 'react';
import { cn } from '../../../utils/cn';

export const IconBanner = memo(
  ({
    url,
    width = 112,
    height = 112,
    alt,
    className,
  }: {
    url?: string;
    width?: number;
    height?: number;
    alt: string;
    className?: string;
  }) => {
    return (
      <div
        className={cn(
          'rounded-full shadow-sm p-2 pointer-events-none',
          className
        )}
        style={{
          width: `${width}px`,
          height: `${height}px`,
        }}
      >
        <img src={url} width='100%' height='100%' alt={alt} />
      </div>
    );
  }
);

IconBanner.displayName = 'IconBanner';
