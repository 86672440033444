import type { BoxProps } from "@mui/material/Box";
// import { useId, forwardRef } from "react";
import { forwardRef } from "react";
import Box from "@mui/material/Box";
// import { useTheme } from "@mui/material/styles";
import { RouterLink } from "../../routes/components";
import { logoClasses } from "./classes";

export type LogoProps = BoxProps & {
  href?: string;
  isSingle?: boolean;
  disableLink?: boolean;
};

export const Logo = forwardRef<HTMLDivElement, LogoProps>(
  (
    {
      width,
      href = "/",
      height,
      isSingle = true,
      disableLink = false,
      className,
      sx,
      ...other
    },
    ref
  ) => {
    const singleLogo = (
      <Box
        alt="Single logo"
        component="img"
        src={`assets/images/HSV.svg`}
        width="100%"
        height="100%"
      />
    );

    const fullLogo = (
      <Box
        alt="Full logo"
        component="img"
        src={`assets/images/HSV.svg`}
        width="100%"
        height="100%"
      />
    );

    const baseSize = {
      width: width ?? 40,
      height: height ?? 40,
      ...(!isSingle && {
        width: width ?? 102,
        height: height ?? 36,
      }),
    };

    return (
      <Box
        ref={ref}
        component={disableLink ? "div" : RouterLink}
        href={href}
        className={`${logoClasses.root} ${className || ""}`}
        aria-label="Logo"
        sx={{
          ...baseSize,
          flexShrink: 0,
          display: "inline-flex",
          verticalAlign: "middle",
          ...(disableLink && { pointerEvents: "none" }),
          ...sx,
        }}
        {...other}
      >
        {isSingle ? singleLogo : fullLogo}
      </Box>
    );
  }
);
