import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import './App.css';
import { AppRoute } from './constant';
import Admin from './admin/main';
import Club from './pages/Club';
import Email from './pages/Email';
import GoodStories from './pages/GoodStories';
import HCMCommunistYouthUnion from './pages/HCMCommunistYouthUnion';
import Home from './pages/Home';
import Introduce from './pages/Introduce';
import News from './pages/News';
import OnlineOffice from './pages/OnlineOffice';
import SignInPage from './pages/SignInPage';
import StudentOf5Merits from './pages/StudentsOf5Merits';
import SupportStudent from './pages/SupportStudent';
import AuthenticatedRoute from './router/authenticate.route';
import { ProtectedRoute } from './router/protected.route';
import store from './store';

const router = createBrowserRouter([
  {
    children: [
      {
        path: AppRoute.ROOT,
        element: <Home />,
      },
      {
        path: AppRoute.NEWS,
        element: <News />,
      },
      {
        path: AppRoute.STUDENT_OF_5_MERITS,
        element: <StudentOf5Merits />,
      },
      {
        path: AppRoute.CLUB,
        element: <Club />,
      },
      {
        path: AppRoute.SUPPORT_STUDENT,
        element: <SupportStudent />,
      },
      {
        path: AppRoute.INTRODUCE,
        element: <Introduce />,
      },
      {
        path: AppRoute.HCMCYoU,
        element: <HCMCommunistYouthUnion />,
      },
      {
        path: AppRoute.EMAIL,
        element: <Email />,
      },
      {
        path: AppRoute.ONLINE_OFFICE,
        element: <OnlineOffice />,
      },
      {
        path: AppRoute.GOOD_STORIES,
        element: <GoodStories />,
      },
      {
        element: <AuthenticatedRoute />,
        children: [
          {
            path: AppRoute.LOGIN,
            element: <SignInPage />,
          },
        ],
      },
      {
        element: <ProtectedRoute />,
        children: [
          {
            path: AppRoute.ADMIN,
            element: <Admin />,
          },
        ],
      },
      {
        path: AppRoute.NOT_FOUND,
        element: <Navigate to={AppRoute.ROOT} />,
      },
    ],
  },
]);

function App() {
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
