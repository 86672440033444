import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { historyApi } from '../apis';
import { History } from '../data';

interface HistoryState {
  history: History[];
  loading: boolean;
  error: string | null;
}

const initialState: HistoryState = {
  history: [],
  loading: false,
  error: null,
};

export const getHistory = createAsyncThunk('history/get', async () => {
  const response = await historyApi.get();
  const data = response.data;
  return data as History[];
});

export const addHistory = createAsyncThunk(
  'history/create',
  async (newHistory: Partial<History>) => {
    const response = await historyApi.create(newHistory);
    const data = await response.data;
    return data as History;
  }
);

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHistory.pending, (state) => {
        (state.loading = true), (state.error = null);
      })
      .addCase(
        getHistory.fulfilled,
        (state, action: PayloadAction<History[]>) => {
          state.loading = false;
          state.history = action.payload;
        }
      )
      .addCase(getHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to get history!';
      })
      .addCase(
        addHistory.fulfilled,
        (state, action: PayloadAction<History>) => {
          state.history.push(action.payload);
        }
      );
  },
});
const historyReducer = historySlice.reducer;
export { historyReducer };
