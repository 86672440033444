import LandingPage from "./LandingPage";
import { useTranslation } from "react-i18next";
const HCMCommunistYouthUnion = () => {
  const { t } = useTranslation();
  document.title = t("dtn")

  return (
    <>
      <LandingPage>
        <div>Hello world!</div>
      </LandingPage>
    </>
  );
};

export default HCMCommunistYouthUnion;
