import { useTranslation } from 'react-i18next';
import { Banner } from '../components/commons/banner/banner';
import { StatsComponent } from '../components/commons/stats';
import LandingPage from './LandingPage';

const Home = () => {
  const { t } = useTranslation();
  document.title = t('hsv');

  // add scroll to top when load page

  return (
    <LandingPage>
      <main className='relative'>
        {/* <SlideShow /> */}
        <Banner />
        <p>Hello</p>
        <StatsComponent />
        <div className='h-screen'></div>
        <div className='h-screen'></div>
        <div className='h-screen'></div>
        {/* <ProgramsComponent />

      <HomePage /> */}
      </main>
      {/* <Grid container spacing={2} sx={{ padding: '16px' }}>
        <Grid item xs={12} sm={8} md={8} lg={8} xl={2}>
          <Paper sx={{ padding: '16px' }}>
            <Article
              category='FOOD'
              title='Dramatically improve your cooking using just your imagination'
              author='Sora Blogging Tips'
              date='March 17, 2016'
              imageUrl={images.slide1}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
          <Paper sx={{ padding: '16px', textAlign: 'center' }}>
            <Typography>Item 2</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
          <Paper
            variant='outlined'
            sx={{ padding: '16px', textAlign: 'center' }}
          >
            <Typography>Item 1</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
          <Paper
            variant='outlined'
            sx={{ padding: '16px', textAlign: 'center' }}
          >
            <Typography>Item 1</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
          <Paper sx={{ padding: '16px', textAlign: 'center' }}>
            <Typography>Item 2</Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <Paper sx={{ padding: '16px', textAlign: 'center' }}>
            <Typography>Item 5</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <Paper sx={{ padding: '16px', textAlign: 'center' }}>
            <Typography>Item 6</Typography>
          </Paper>
        </Grid>
      </Grid> */}
    </LandingPage>
  );
};

export default Home;
