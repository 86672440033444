export const LocalStorageKey = {
  LAST_HREF: 'lastHref',
};

export const Action = {
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  CREATE: 'Create',
  UPDATE: 'Update',
  DELETE: 'Delete',
};

export const IMG_PADDING = 12;
